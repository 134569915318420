
import Playcard from "./Playcard" ;



const Gameplay =()=>{

    return(
        <div className="base3_bg relative flex justify-center overflow-hidden h-[1500px] ">
            <div className="absolute -left-[40px] top-[0px] hidden sp1900min:flex ">
                <img src="./new4.png" width='448px' height='375px' alt="" />
            </div>
            <div className="absolute -left-[40px] top-[0px] hidden sp1600min:flex sp1900min:hidden ">
                <img src="./new4.png" width='350px' height='375px' alt="" />
            </div>
            
            
            <div className="absolute -right-[200px] top-[371px] hidden sp1900min:flex  ">
                <img src="./new5.png" width='398px' height='392px' alt="" />
            </div>
            <div className="absolute -right-[150px] top-[371px] hidden sp1600min:flex sp1900min:hidden  ">
                <img src="./new5.png" width='308px' height='392px' alt="" />
            </div>
            {/*  */}
            <div className="w-[100%] pt-[20px]  ">
                <div className="text-[white] text-[28px] md:text-[60px] flex justify-center font-bold text-center items-center ">
                    Gameplay
                </div>
                <div className=" text-[12px] md:text-[19px] text-[white] flex flex-col items-center pt-[33px]">
                    <div className="px-[55px] sp1100min:px-[5px]  md:w-[945px] flex justify-center text-center">
                        As each user's preferred profit direction is different, GoldeFy can be realized in various ways and has a simple structure that allows you to easily play and enjoy.
                    </div>
                </div>
                <div className="  pt-[80px] flex flex-wrap justify-center px-[0px]  sp950min:px-[100px] sp1106min:px-[200px] sp1415min:px-[0px] ">
                    <Playcard url="e1.png" title="IDLE MINING" context="You can mine g-token automatically by creating an account and activating the start button every 12 hours. g-token has various uses within the goldefy app." />
                    <Playcard url="e2.png" title="MINI GAMES" context="Goldefy minigames are simple yet addictive games that give you the chance to play for free and win prizes from lucky number provider." />
                    <Playcard url="e3.png" title="BREED & ENHANCEMENT" context="Another way to make earnings with Goldy NFT is to obtain goldy NFT through breeding and increase the value of goldy NFT through enhancement." />
                    <Playcard url="e4.png" title="RANKINGS" context="Ranking will be an indicator of all prizes, and prizes will be paid out in a fair and transparent manner. You have a chance to win a prize when you rank in the rankings, not the goal of being 1st." />
                </div>

            </div>
        </div>
    )
}

export default Gameplay ;
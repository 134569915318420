

const SocialLink =()=>{

    return(
        <div className="fixed select-none	 right-0 top-[50%] bg-[#171717] opacity-[74%]  rounded-l-[21px] md:rounded-l-[39px]  w-[54px] md:w-[80px] h-[170px] md:h-[294px] z-[3000]  flex flex-col items-center justify-around py-[9px] md:py-[31px] px-[17px] md:px-[20px] ">
            
            <div className="hidden md:flex">
                <a href="https://discord.gg/goldefy"  target="_blank" rel="">
                    <img src="./icons/discord.svg"  width='39px' height='39px' alt="" />
                </a>
            </div>
            <div className="flex md:hidden">
                <a href="https://discord.gg/goldefy" target="_blank" rel="">
                    <img src="./icons/discord.svg"  width='24px' height='24px' alt="" />
                </a>
            </div>
            <div className="hidden md:flex">
                <a href="https://t.me/+b6kDnoE4t7s2OTJl" target="_blank" rel="">
                    <img src="./icons/telegram.svg" width='39px' height='39px' alt="" />
                </a>
            </div>
            <div className="flex md:hidden">
                <a href="https://t.me/+b6kDnoE4t7s2OTJl" target="_blank" rel="">
                    <img src="./icons/telegram.svg" width='24px' height='24px' alt="" />
                </a>
            </div>
            <div className="hidden md:flex">
                <a href="https://twitter.com/Goldefy_" target="_blank" rel="">
                    <img src="./icons/twitter.svg" width='39px' height='39px' alt="" />
                </a>
            </div>
            <div className="flex md:hidden">
                <a href="https://twitter.com/Goldefy_" target="_blank" rel="">
                    <img src="./icons/twitter.svg" width='24px' height='24px' alt="" />
                </a>
            </div>
            <div className="hidden md:flex">
                <a href="https://medium.com/@GoldeFy" target="_blank" rel="">
                    <img src="./icons/medium.svg" width='39px' height='39px' alt="" />
                </a>
            </div>
            <div className="flex md:hidden">
                <a href="https://medium.com/@GoldeFy" target="_blank" rel="">
                    <img src="./icons/medium.svg" width='24px' height='24px' alt="" />
                </a>
            </div>
            <div className="hidden md:flex">
                <a href="https://www.reddit.com/r/GoldeFyNFT/" target="_blank" rel="">
                    <img src="./icons/reddit.svg" width='39px' height='39px' alt="" />
                </a>
            </div>
            <div className="flex md:hidden">
                <a href="https://www.reddit.com/r/GoldeFyNFT/" target="_blank" rel="">
                    <img src="./icons/reddit.svg" width='24px' height='24px' alt="" />
                </a>
            </div>
            
        </div>
    )
}

export default SocialLink ;
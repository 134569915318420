

const NFTcard =({url , inx})=>{

    return(
        <div className="flex px-[9px] md:px-[14px]" key={inx}>
            <div className="nft_background hidden justify-center px-[40px] py-[70px] md:flex userDrag">
                 <img className="userDrag" src={`./${url}`} width="200px"  height='250px' alt="" />
            </div>
            <div className="nft_background_mobile flex justify-center px-[25px] py-[40px] md:hidden userDrag">
                 <img className="userDrag" src={`./${url}`} width="137px"  height='139px' alt="" />
            </div>
        </div>
    )
}

export default NFTcard ;
const onOk = (response) => {
    return response;
}

const onError = (err) => {
    console.log('err' + err) // for debug
    return Promise.reject(err)
}

export default function setupAxios(axios) {
    axios.defaults.baseURL = process.env.REACT_APP_PUBLIC_API;
    axios.defaults.timeout = 10000;
    axios.interceptors.response.use(onOk, onError);
    axios.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem('token');
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (err) => Promise.reject(err)
    );
  }
  

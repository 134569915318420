
import MemberCard from "./MemberCard" ; 

const Teammember =()=>{

    const members =[
        {id:'4' , name:'Spark' ,Linkurl:'', role:'FOUNDER' , img:'Spark.jpg'},
        {id:'1' , name:'Chan' ,Linkurl:'https://www.linkedin.com/in/chan-lee-396869225/', role:'CEO' , img:'1.png'},
        {id:'2' , name:'Eddan' ,Linkurl:'https://www.linkedin.com/in/goldefy/', role:'COO' , img:'2.png'},
        {id:'2' , name:'Jake' ,Linkurl:'https://www.linkedin.com/in/jakenguyen/', role:'CAO' , img:'25.jpg'},
        {id:'5' , name:'Donald' ,Linkurl:'', role:'PM' , img:'pm.jpg'},
        {id:'6' , name:'Michael' ,Linkurl:'https://www.linkedin.com/in/michael-chan-9a9451256/', role:'Tech Lead' , img:'tech_lead.jpg'},
        {id:'7' , name:'Tam' ,Linkurl:'', role:'Dev Ops' , img:'dev_ops.jpg'},
        {id:'8' , name:'Thomas' ,Linkurl:'https://www.linkedin.com/in/thomas-pham-goldefy', role:'Frontend Developer' , img:'fe_dev.jpg'},
        {id:'9' , name:'Jung' ,Linkurl:'', role:'Client Developer' , img:'19.jpg'},
        {id:'10' , name:'Renn' ,Linkurl:'https://www.linkedin.com/in/ian-dy-a8838a227/', role:'Art Director' , img:'5.png'},
        {id:'11' , name:'Iklee' ,Linkurl:'https://www.linkedin.com/in/mickey-kim-5542a9107/', role:'cilent backend Developer' , img:'33.jpg'},
        {id:'12' , name:'Minji' ,Linkurl:'', role:'Creative Design' , img:'7.png'},
        {id:'13' , name:'Jiyoung' ,Linkurl:'', role:'Creative Design UI/UX' , img:'8.jpg'},
        {id:'14' , name:'Sangwoo' ,Linkurl:'https://www.linkedin.com/in/sang-woo-kwak-6b134a226/', role:'Game developer' , img:'9.jpg'},
        // {id:'10' , name:'JK' ,Linkurl:'', role:'Game designer' , img:'9.jpg'},
        {id:'15' , name:'Jay' ,Linkurl:'', role:'Technical Blockchain Lead' , img:'11.jpg'},
        {id:'16' , name:'Brian' ,Linkurl:'https://www.linkedin.com/in/brian-park-b29550226/', role:'Server Engineer' , img:'12.png'},
        // {id:'13' , name:'JiTae' ,Linkurl:'', role:'Software Engineer' , img:'13.png'},
        // {id:'14' , name:'Cai Xiao Loang' ,Linkurl:'', role:'Marketing Manager' , img:'14.png'},
        
        
        {id:'17' , name:'Sunny' ,Linkurl:'', role:'CCO' , img:'18.jpg'},
        
    ]

    return(
        <div className="bg-[#071F4D] pt-[40px] pb-[150px] overflow-hidden select-none	 " >
            <div className="text-[white] text-[28px] md:text-[60px] flex justify-center font-bold text-center items-center ">
                Goldefy's <span className="text-[#F5C83B]">Team</span> 
            </div>
            <div className=" text-[12px] md:text-[13px] text-[white] flex flex-col items-center pt-[14px] md:pt-[33px]">
                <div className="px-[40px] md:px-[150px] flex justify-center text-center ">
                    Fully integrated team with proven track record of building successful blockchain projects,covering strategy, development, partnerships, marketing, social media and community.<br/> Every member of the team is fully committed with long term visions for the success of the project.
                </div>
            </div>
            <div className="gird_css mt-[53px] ">
                {members.map((item , inx)=>(
                    <MemberCard img={item.img} name ={item.name} role={item.role} linkedin={item.Linkurl} key={inx}  />
                ))}
            </div>
        </div>
    )
}

export default Teammember ;
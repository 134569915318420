import axios from 'axios';
import cn from 'classnames';
import { Link } from "react-router-dom";


import { useEffect, React, useState, Fragment } from 'react';


const Footer = () => {

    const [isEmail, setEmail] = useState('');
    const [isSuccess, setSuccess] = useState('');
    const [isError, setError] = useState(false);

    const subscribe = () => {
        if (isEmail == '') { setSuccess('Please input Email.'); setError(true); return; }
        axios.post('https://app.sgwidget.com/v2/api/newsletter-signup', {
            first_name: '',
            last_name: '',
            email: isEmail,
            token: 'ef617d04bb3495a495a00d7004bfb425'
        })
            .then(function (resp) {
                // console.log(resp) ;
                // console.log(resp.data.message) ;
                setSuccess(resp.data.message);
                setError(false);
            })
            .catch(function (error) {
                // console.log(error.response.data.email[0]) ;
                setSuccess(error.response.data.email[0]);
                setError(true);
            });
        axios.post('https://rank.goldefy.com/api/sendMail', {
            EMAIL: isEmail
        })
    }
    const calc = (e) => {
        // console.log(e.target.value) ;
        setEmail(e.target.value);
    }

    return (
        <div className="flex flex-col" id='footer'>
            <div className="bg-[#1D1D1D] hidden sp1100min:flex flex-wrap justify-around  items-center pb-[10px]  ">
                <div className=" flex flex-wrap justify-center items-center">
                    <div className="-mt-[80px] mr-[10px] z-10">
                        <img src="./new6.png" width='272px' height='275px' alt="" />
                    </div>
                    <div className="flex flex-col w-[400px]" >
                        <div className=" text-white text-[20px] font-semibold mb-[16px] ">
                            Don't miss out our NFT and Token Sale Announcements.
                        </div>
                        <div className={cn("text-[green] text-[20px] w-[100%] flex justify-start pl-[10px] pb-[10px] -mt-[15px] ", isError && 'text-[red]')}>
                            {isSuccess}
                        </div>

                        <div className="border-[#4E4E4E] border-[1px] rounded-[16px] bg-[#000000] flex justify-between px-[20px] items-center w-[406px] h-[55px] ">
                            <input className="select-none	 text-[white] text-[11px] md:text-[17px]  w-[90%] h-[25px] md:h-[50px] bg-[#000000] no-border " placeholder='Email' onChange={(e) => { calc(e) }} />
                            {/* </div> */}
                            <div className="text-[#BBBBBB] text-[15px] font-semibold cursor-pointer select-none	" onClick={subscribe}>
                                SUBSCRIBE
                            </div>
                        </div>
                    </div>

                </div>
                <div className="flex flex-col ">
                    <div className="flex justify-center items-center select-none	">
                        <a href="https://discord.gg/goldefy" target="_blank" rel="">
                            <div className="bg-[#9076E9] rounded-[13px] w-[196px] h-[53px] flex justify-around items-center px-[18px] ">
                                <img src="./discrod.png" width='38px' height='38px' alt="" />
                                <div className="text-[white] text-[15px] ">
                                    Join Discord
                                </div>
                            </div>
                        </a>
                        <a href="https://t.me/+b6kDnoE4t7s2OTJl" target="_blank" rel="">
                            <div className="bg-[#478AFF] rounded-[13px] w-[196px] h-[53px] flex justify-around items-center px-[18px] ml-[10px]">
                                <img src="./telegram.png" width='38px' height='38px' alt="" />
                                <div className="text-[white] text-[15px] ">
                                    Join Telegram
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className='text-[white] text-[12px] pt-[18px] '>
                        Chat with the community, ask questions, get involved in competitions and more!
                    </div>
                </div>
            </div>
            <div className="bg-[#1D1D1D] flex sp1100min:hidden flex-wrap justify-around  items-center pb-[10px]  ">
                <div className=" flex flex-wrap justify-center items-center">
                    <div className="hidden md:flex -mt-[80px] mr-[10px] z-10">
                        <img src="./new6.png" width='272px' height='275px' alt="" />
                    </div>
                    <div className="flex md:hidden -mt-[10px] mr-[10px] z-10">
                        <img src="./new6.png" width='157px' height='158px' alt="" />
                    </div>
                    <div className="flex flex-col items-start ">
                        <div className="flex flex-col max-w-[400px] mt-[20px]" >
                            <div className=" text-white  text-[10px] md:text-[20px] font-semibold mb-[16px] w-[220px] md:w-[400px] ">
                                Don't miss out our NFT and Token Sale Announcements.
                            </div>
                            <div className={cn("text-[green] text-[11px] md:text-[20px] w-[100%]  pl-[10px] pb-[5px] -mt-[10px] ", isError && 'text-[red]')}>
                                {isSuccess}
                            </div>
                            <div className="border-[#4E4E4E] border-[1px] rounded-[16px] bg-[#000000] flex justify-between px-[20px] items-center w-[216px] md:w-[406px] h-[29px] md:h-[55px] ">
                                <input className="text-[white] text-[11px] md:text-[17px]  w-[90%] h-[25px] md:h-[50px] bg-[#000000] no-border " placeholder='Email' onChange={(e) => { calc(e) }} />

                                <div className="text-[#BBBBBB] text-[10px] md:text-[15px] font-semibold cursor-pointer	" onClick={subscribe}>
                                    SUBSCRIBE
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col mt-[10px] select-none	">
                            <div className="flex justify-center items-center">
                                <a href="https://discord.gg/goldefy" target="_blank" rel="">
                                    <div className="bg-[#9076E9] rounded-[13px] w-[104px] md:w-[196px] h-[28px] md:h-[53px] flex justify-around items-center px-[10px] md:px-[18px] ">
                                        <div className="hidden md:flex">
                                            <img src="./discrod.png" width='38px' height='38px' alt="" />
                                        </div>
                                        <div className="flex md:hidden">
                                            <img src="./discrod.png" width='21px' height='20px' alt="" />
                                        </div>
                                        <div className="text-[white] text-[8px] md:text-[15px] ">
                                            Join Discord
                                        </div>
                                    </div>
                                </a>
                                <a href="https://t.me/+b6kDnoE4t7s2OTJl" target="_blank" rel="">
                                    <div className="bg-[#478AFF] rounded-[13px] w-[104px] md:w-[196px] h-[28px] md:h-[53px] flex justify-around items-center px-[10px] md:px-[18px] ml-[10px]">
                                        <div className="hidden md:flex">
                                            <img src="./telegram.png" width='38px' height='38px' alt="" />
                                        </div>
                                        <div className="flex md:hidden">
                                            <img src="./telegram.png" width='18px' height='18px' alt="" />
                                        </div>
                                        <div className="text-[white] text-[8px] md:text-[15px] ">
                                            Join Telegram
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className='text-[white] text-[8px] w-[200px] pl-[10px] pt-[5px]  '>
                                Chat with the community, ask questions, get involved in competitions and more!
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="bg-[#000000] flex justify-center items-center text-[white] text-[9px] md:text-[14px] mt-[0px]  xl:-mt-[20px] ">
                <div className="font-semibold">
                    © 2022 Goldefy. All rights reserved.
                </div>
                <div className="h-[59px] flex justify-center items-center px-[10px]">
                    <div className=''>
                        <Link to='/privacyPolicy'>Privacy Policy | </Link>
                    </div>
                    <div className=''>
                        <Link to='/term'>Terms & Conditions </Link>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default Footer;
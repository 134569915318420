import Header from './Header' ;
import LogoMain from './LogoMain' ;
import Smart from './Smart' ;
import Certik from './Certik' ;
import Gameplay from './Gameplay' ;
import NFT from './NFT' ;
import Goldypot from './Goldypot' ;
import Roadmap from './Roadmap' ;
import Teammember from './Teammember/Teammember' ;
import Partners from './Partners/partners' ;
import Footer from './Footer/Footer' ; 
import SocialLink from './SocialLink' ;

import { useEffect, React ,useState,Fragment } from 'react' ;


const Home =()=>{
    return (
        <div className='relative select-none'>
            <SocialLink />
            <Header />
            <LogoMain />
            <Smart />
            <Certik />
            <Gameplay />
            <NFT />
            <Goldypot />
            <Roadmap />
            <Teammember />
            <Partners />
            <Footer />

        </div>    
    )
}

export default Home ;
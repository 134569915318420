import { React } from 'react';
import Header from '../Header';
const Guide = () => {

    return (
        <div>
            <Header otherUrl={true} />
            <div className='bg-[#212e49] flex justify-center  pt-[100px] md:pt-[240px] pb-[50px] md:pb-[100px] area-preOrder'>
                <div className='bg-[#000f21] w-[1240px] px-[25px] md:px-[60px] pt-[25px] md:pt-[30px] pb-[50px] md:pb-[100px] text-[white] '>

                    <div className=' text-[28px] font-bold flex justify-center'>
                        Goldy Mining Guide
                    </div>
                    <div className="pt-[20px]  text-[16px] pb-[10px] ">
                        - Users can start automatic mining by clicking the Start button.
                    </div>
                    <div className="text-[16px] pb-[10px] ">
                        - At this time, you can continue auto-mining as long as Goldy's stamina time, and when Goldy's stamina is exhausted, you can start auto-mining again by pressing the Start button.
                    </div>
                    <div className="text-[16px] pb-[10px] ">
                        - Automatic mining does not stop until the remaining stamina time even if you leave the mining app or close the app, and it does not drain your phone's battery.
                    </div>
                    <div className="text-[16px] pb-[10px] ">
                        - However, mining will stop when you log out.
                    </div>

                    <div className='text-[20px] pb-[10px] pt-[40px] font-semibold '>
                        How To Play
                    </div>
                    <div className="  text-[16px] pb-[10px] ">
                        - You can start the mining game through the "Start" button at the bottom center, and Goldy's stamina automatically mines G-token for 12 hours.
                    </div>
                    <div className="  text-[16px] pb-[10px] ">
                        - Through the "Stamina Recharge" button at the bottom right, you can recharge Goldy's stamina to 12 hours and automatically mine, regardless of Goldy's remaining stamina time.
                    </div>

                    <div className='text-[20px] pb-[10px] pt-[40px] font-semibold '>
                        Team Reward
                    </div>
                    <div className="  text-[16px] pb-[10px] ">
                        - Users can add 100 team members to "My Team" if they are invited by another user's referral code and accepted.
                    </div>
                    <div className="  text-[16px] pb-[10px] ">
                        - When an invitation is made, both the invited user and the invited user are equally added to "My Team".
                    </div>
                    <div className="  text-[16px] pb-[10px] ">
                        - Team boost rules are calculated based on the active status of team members, so you can invite more active friends as team members to get a high team boost.
                    </div>
                    <div className="  text-[16px] pb-[10px] ">
                        - Please refer to the table to check the exact team boost.
                    </div>

                    <div className='text-[20px] pb-[10px] pt-[40px] font-semibold '>
                        Team Boost Graph attached
                    </div>
                    <div className="  text-[16px] pb-[10px] ">
                        - Q. Is Goldy's stamina fixed at 12 hours? How do I increase it?
                    </div>
                    <div className="  text-[16px] pb-[10px] ">
                        - A. Goldy with stamina that automatically mines for 12 hours. Goldy's stamina can be increased in the metaverse game.
                        However, you can recharge it back to 12 hours at any time, regardless of the remaining stamina time, through the "Stamina Recharge" button.
                    </div>
                    <div className="  text-[16px] pb-[10px] ">
                        - Q. Do cell phone specifications affect the speed of automatic mining?
                    </div>
                    <div className="  text-[16px] pb-[10px] ">
                        - A. GoldeFy: Pre-Mining APP is an eco-friendly automatic mining method because it does not actually use the CPU and GPU of the mobile phone because it is a compensation policy that borrows only the concept of mining, not mining through actual node proof that consumes a large amount of electricity. .
                        So, the specifications of the mobile phone do not affect the speed of mining, only Team Boost affects the amount of tokens automatically mined.
                    </div>
                    <div className="  text-[16px] pb-[10px] ">
                        Q. Where are the mined G-tokens used?
                    </div>
                    <div className="  text-[16px] pb-[10px] ">
                        A. G-token, a currency to be used in the GoldeFy Metaverse Game, is used as the basic currency for breeding/item purchases in the game, and can be transferred to your wallet by swapping with $GOD.
                    </div>
                    <div className="  text-[16px] pb-[10px] ">
                        Q. Do I have to create My Team?
                    </div>
                    <div className="  text-[16px] pb-[10px] ">
                        A. When you add a friend to My Team, a team boost of up to +1,000% is added automatically when you are online with your teammates.
                    </div>
                    <div className="  text-[16px] pb-[10px] ">
                        Q. Is multi-account mining possible?
                    </div>
                    <div className="  text-[16px] pb-[10px] ">
                        A. Basically, multiple accounts for one person are not supported. We plan to filter in various ways, and if it is determined that there are multiple accounts, the account will be suspended and deleted, and all tokens mined in the meantime will be processed unissued.
                    </div>
                    <div className="  text-[16px] pb-[10px] ">
                        Q. When will the GoldeFy Metaverse Game launch?
                    </div>
                    <div className="  text-[16px] pb-[10px] ">
                        A. The launch is scheduled for June 22, but we are doing our best to open sooner. Thank you so much for your interest and love from Goldians.
                    </div>
                    <div className="  text-[16px] pb-[10px] ">
                        For more details, you can get answers from administrators or users through GoldeFy's official Discord.
                        <br />   <a href="https://discord.gg/4eVxZP4He4" target="_blank">https://discord.gg/4eVxZP4He4</a>
                    </div>



                </div>

            </div>
        </div>
    )
}

export default Guide;
import {React} from 'react';
import Header from '../Header';
const Disclaimer = () => {

    return (
        <div>
            <Header otherUrl={true} />
            <div className='bg-[#212e49] flex justify-center  pt-[100px] md:pt-[240px] pb-[50px] md:pb-[100px] area-preOrder'>
                <div className='bg-[#000f21] w-[1240px] px-[25px] md:px-[60px] pt-[25px] md:pt-[30px] pb-[50px] md:pb-[100px] text-[white] '>

                    <div className=' text-[28px] font-bold flex justify-center'>
                    Disclaimer
                    </div>
                    <div className="pt-[20px]  text-[16px] pb-[10px] ">
                        - Last updated: February 16, 2022
                    </div>


                    <div className='text-[20px] pb-[10px] pt-[40px] font-semibold '>
                    Interpretation and Definitions
                    </div>
                    <div className='text-[20px] pb-[10px] pt-[40px] font-semibold '>
                    Interpretation
                    </div>
                    <div className="  text-[16px] pb-[10px] ">
                        - The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
                    </div>

                    <div className='text-[20px] pb-[10px] pt-[40px] font-semibold '>
                    Definitions
                    </div>
                    <div className="  text-[16px] pb-[10px] ">
                        - For the purposes of this Disclaimer:
                    </div>
                    <div className="  text-[16px] pb-[10px] ">
                        - Company (referred to as either "the Company", "We", "Us" or "Our" in this Disclaimer) refers to GoldeFy Studio Ltd, Vistra Corporate Services Centre, Suite 23, 1st Floor, Eden Plaza, Eden Island.
                    </div>
                    <div className="  text-[16px] pb-[10px] ">
                        - Service refers to the Application.
                    </div>
                    <div className="  text-[16px] pb-[10px] ">
                        - You means the individual accessing the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.
                    </div>
                    <div className="  text-[16px] pb-[10px] ">
                        - Application means the software program provided by the Company downloaded by You on any electronic device named GoldeFy:Pre-Mining.
                    </div>

                    <div className='text-[20px] pb-[10px] pt-[40px] font-semibold '>
                    Disclaimer
                    </div>
                    <div className="  text-[16px] pb-[10px] ">
                        - The information contained on the Service is for general information purposes only.
                    </div>
                    <div className="  text-[16px] pb-[10px] ">
                        - The Company assumes no responsibility for errors or omissions in the contents of the Service.
                    </div>
                    <div className="  text-[16px] pb-[10px] ">
                        - In no event shall the Company be liable for any special, direct, indirect, consequential, or incidental damages or any damages whatsoever, whether in an action of contract, negligence or other tort, arising out of or in connection with the use of the Service or the contents of the Service. The Company reserves the right to make additions, deletions, or modifications to the contents on the Service at any time without prior notice.
                    </div>
                    <div className="  text-[16px] pb-[10px] ">
                        - The Company does not warrant that the Service is free of viruses or other harmful components.
                    </div>


                    <div className='text-[20px] pb-[10px] pt-[40px] font-semibold'>
                    External Links Disclaimer
                    </div>
                    <div className="  text-[16px] pb-[10px] ">
                        - The Service may contain links to external websites that are not provided or maintained by or in any way affiliated with the Company.
                    </div>
                    <div className="  text-[16px] pb-[10px] ">
                        - Please note that the Company does not guarantee the accuracy, relevance, timeliness, or completeness of any information on these external websites.
                    </div>

                    <div className='text-[20px] pb-[10px] pt-[40px] font-semibold '>
                    Errors and Omissions Disclaimer
                    </div>
                    <div className="  text-[16px] pb-[10px] ">
                        - The information given by the Service is for general guidance on matters of interest only. Even if the Company takes every precaution to insure that the content of the Service is both current and accurate, errors can occur. Plus, given the changing nature of laws, rules and regulations, there may be delays, omissions or inaccuracies in the information contained on the Service.
                    </div>
                    <div className="  text-[16px] pb-[10px] ">
                        - The Company is not responsible for any errors or omissions, or for the results obtained from the use of this information.
                    </div>

                    <div className='text-[20px] pb-[10px] pt-[40px] font-semibold '>
                    Fair Use Disclaimer
                    </div>
                    <div className="  text-[16px] pb-[10px] ">
                        - The Company may use copyrighted material which has not always been specifically authorized by the copyright owner. The Company is making such material available for criticism, comment, news reporting, teaching, scholarship, or research.
                    </div>
                    <div className="  text-[16px] pb-[10px] ">
                        - The Company believes this constitutes a "fair use" of any such copyrighted material as provided for in section 107 of the United States Copyright law.
                    </div>
                    <div className="  text-[16px] pb-[10px] ">
                        - If You wish to use copyrighted material from the Service for your own purposes that go beyond fair use, You must obtain permission from the copyright owner.
                    </div>

                    <div className='text-[20px] pb-[10px] pt-[40px] font-semibold '>
                    Views Expressed Disclaimer
                    </div>
                    <div className="  text-[16px] pb-[10px] ">
                        - The Service may contain views and opinions which are those of the authors and do not necessarily reflect the official policy or position of any other author, agency, organization, employer or company, including the Company.
                    </div>
                    <div className="  text-[16px] pb-[10px] ">
                        - Comments published by users are their sole responsibility and the users will take full responsibility, liability and blame for any libel or litigation that results from something written in or as a direct result of something written in a comment. The Company is not liable for any comment published by users and reserves the right to delete any comment for any reason whatsoever.
                    </div>


                    <div className='text-[20px] pb-[10px] pt-[40px] font-semibold '>
                    No Responsibility Disclaimer
                    </div>
                    <div className="  text-[16px] pb-[10px] ">
                        - The information on the Service is provided with the understanding that the Company is not herein engaged in rendering legal, accounting, tax, or other professional advice and services. As such, it should not be used as a substitute for consultation with professional accounting, tax, legal or other competent advisers.
                    </div>
                    <div className="  text-[16px] pb-[10px] ">
                        - In no event shall the Company or its suppliers be liable for any special, incidental, indirect, or consequential damages whatsoever arising out of or in connection with your access or use or inability to access or use the Service.
                    </div>

                    <div className='text-[20px] pb-[10px] pt-[40px] font-semibold '>
                    "Use at Your Own Risk" Disclaimer
                    </div>
                    <div className="  text-[16px] pb-[10px] ">
                        - All information in the Service is provided "as is", with no guarantee of completeness, accuracy, timeliness or of the results obtained from the use of this information, and without warranty of any kind, express or implied, including, but not limited to warranties of performance, merchantability and fitness for a particular purpose.
                    </div>
                    <div className="  text-[16px] pb-[10px] ">
                        - The Company will not be liable to You or anyone else for any decision made or action taken in reliance on the information given by the Service or for any consequential, special or similar damages, even if advised of the possibility of such damages.
                    </div>

                    <div className='text-[20px] pb-[10px] pt-[40px] font-semibold '>
                    Contact Us
                    </div>
                    <div className="  text-[16px] pb-[10px] ">
                        - If you have any questions about this Disclaimer, You can contact Us:
                    </div>
                    <div className="  text-[16px] pb-[10px] ">
                        - By email: contact@goldefy.com
                    </div>
                    <div className="  text-[16px] pb-[10px] ">
                        - By visiting this page on our website: <a href="https://goldefy.com" target="_blank">https://goldefy.com</a>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Disclaimer;
import { useEffect, React ,useState,Fragment } from 'react';

import copy from 'copy-to-clipboard' ;


const Smart =()=>{

    const CopyContract =()=>{
        copy("0x2Aaa5cb023cf458E7dFa765FD48b64Cd95995ab7") ;
    }

    return(
        <div className="base2_bg  ">
            <div className='relative flex justify-center overflow-hidden backdrop-blur-[5px] '>
                <div className="absolute -left-[120px] top-[405px] ">
                    <div className="hidden sp1900min:flex">
                        <img src="./new3.png" width='525px' height='465px' alt="" />
                    </div>
                    <div className="hidden sp1600min:flex sp1900min:hidden">
                        <img src="./new3.png" width='425px' height='465px' alt="" />
                    </div>
                    <div className="hidden sp1400min:flex sp1600min:hidden mt-[100px]">
                        <img src="./new3.png" width='325px' height='465px' alt="" />
                    </div>
                    <div className="hidden sp425min:flex sp768min:hidden mt-[170px] ml-[90px]">
                        <img src="./new3.png" width='149px' height='465px' alt="" />
                    </div>
                </div>
                <div className="absolute -right-[100px] top-[205px] ">
                    <div className="hidden sp1900min:flex">
                        <img src="./new2.png" width='610px' alt="" />
                    </div>
                    <div className="hidden sp1600min:flex sp1900min:hidden">
                        <img src="./new2.png" width='510px' alt="" />
                    </div>
                    <div className="hidden sp1400min:flex sp1600min:hidden mt-[50px]">
                        <img src="./new2.png" width='410px' alt="" />
                    </div>
                    <div className="hidden sp425min:flex sp768min:hidden mt-[130px] mr-[30px]">
                        <img src="./new2.png" width='187px' alt="" />
                    </div>
                </div>
                <div className="max-w-[1080px] pt-[180px] ">
                    <div className="text-[white] text-[28px] md:text-[60px] flex justify-center font-bold text-center items-center ">
                        Smart Contract
                    </div>
                    <div className=" text-[12px] md:text-[15px] text-[white] flex flex-col items-center pt-[25px] md:pt-[45px]">
                        {/* <div className=""> */}
                        <div className="px-[40px] sp900min:px-[350px] flex justify-center text-center">
                            GOD-Token is the Main governance token. It will be used to buy Goldy, Breed and Enhance Goldy.
                        </div>
                        <div className="z-10 flex justify-center text-center pt-[20px] px-[40px] sp900min:px-[200px]">
                            GoldeFy is a combination of idle game, and metaverse mining game all merged by blockchain and NFT technologies. This will be the new era among NFT game market. Users get to choose their position whether to play by themselves or let their characters mine automatically.
                        </div>
                        
                        {/* </div> */}
                    </div>
                    <div className=" mt-[26px] bg-[#022750] rounded-[47px] max-w-[1080px] h-[278px]  flex-col items-start px-[50px] mx-[20px] hidden sp900min:flex  ">
                        <div className=" font-semibold text-[39px] text-[white] py-[37px]  ">
                            Contract Address
                        </div>
                        <div className="bg-[#FFFFFF] rounded-[16px] w-[100%] h-[79px] px-[15px] py-[10px] flex justify-between ">
                            <div className="flex justify-between w-[90%] px-5">
                                <div className="flex ">
                                    <div className="pr-[16px]  ">
                                        <img src="./ES.jpg" width='61px' height='61px' alt="" />
                                    </div>
                                    <div className="flex justify-between max-w-[680px] ">
                                        <div className="flex flex-col items-start mt-[6px]  ">
                                            <div className="text-[black] font-bold text-[19px]  ">
                                                GOD Token Official Contract Address
                                            </div>
                                            <div className="text-[#238CFF]  text-[19px] -mt-[5px] ">
                                                0x2Aaa5cb023cf458E7dFa765FD48b64Cd95995ab7
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex justify-center items-center cursor-pointer " onClick={CopyContract}>
                                    <img src="./copy.png" width='26px' height='26px' alt="" />
                                </div>
                            </div>
                            <a href="https://pancakeswap.finance/swap?inputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56&outputCurrency=0x2Aaa5cb023cf458E7dFa765FD48b64Cd95995ab7" target="_blank" rel="">
                                <div className="bg-[#3DD3DC] rounded-[14px] w-[178px] h-[57px] px-[10px] flex justify-around items-center cursor-pointer	 ">
                                    <div className="cursor-pointer	">
                                        
                                            <img src="./pan.png" width='41px' height='41px' alt="" />
                                    </div>
                                    <div className="text-[white] text-[17px] font-bold  ">
                                        Trade Now
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className=" mt-[26px] bg-[#022750] rounded-[30px] sp520min:rounded-[47px] max-w-[1080px] h-[200px] sp520min:h-[272px] md:h-[340px]  flex flex-col items-center px-[20px] md:px-[50px] mx-[20px]  sp900min:hidden">
                        <div className=" font-semibold text-[17px] sp520min:text-[21px] md:text-[39px] text-[white] py-[20px] sp520min:py-[37px]  ">
                            Contract Address
                        </div>
                        <div className=" w-[100%] ">
                            <div className="bg-[#FFFFFF] rounded-[12px] sp520min:rounded-[16px]  h-[50px] sp520min:h-[63px] md:h-[79px] px-[0px] md:px-[15px] py-[4px] sp520min:py-[10px] flex justify-between ">
                                <div className="flex justify-between w-[100%] px-[5px]">
                                    <div className="flex ">
                                        <div className="pr-[5px] sp520min:pr-[16px] md:flex hidden">
                                            <img src="./ES.jpg" width='61px' height='61px' alt="" />
                                        </div>
                                        <div className="pr-[5px] hidden sp415min:flex sp520min:pr-[16px] md:hidden  ">
                                            <img src="./ES.jpg" width='34px' height='34px' alt="" />
                                        </div>
                                        <div className="pl-[5px] sp415min:pl-[0px] flex justify-between max-w-[680px] ">
                                            <div className="flex flex-col items-start mt-[6px]  ">
                                                <div className="text-[black] font-bold text-[11px] sp340min:text-[12px] sp520min:text-[14px] md:text-[19px]  ">
                                                    GOD Token Official Contract Address
                                                </div>
                                                <div className="text-[#238CFF] text-[9px] sp340min:text-[10px] sp360min:text-[11px] sp520min:text-[15px] md:text-[19px] -mt-[5px] ">
                                                    0x2Aaa5cb023cf458E7dFa765FD48b64Cd95995ab7
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" justify-center items-center hidden md:flex cursor-pointer " onClick={CopyContract}>
                                        <img src="./copy.png" width='26px' height='26px' alt="" />
                                    </div>
                                    <div className=" justify-center items-center flex md:hidden  cursor-pointer " onClick={CopyContract}>
                                        <img src="./copy.png" width='15px' height='15px' alt="" />
                                    </div>
                                </div>
                            </div>
                            <a href="https://pancakeswap.finance/swap?inputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56&outputCurrency=0x2Aaa5cb023cf458E7dFa765FD48b64Cd95995ab7" target="_blank" rel="">
                                <div className="bg-[#3DD3DC] rounded-[12px] sp520min:rounded-[14px] -translate-x-1/2 ml-[50%] mt-[20px] sp520min:mt-[30px]  w-[150px] sp520min:w-[178px] h-[45px] sp520min:h-[57px] px-[10px] sp520min:px-[20px] flex justify-around items-center cursor-pointer	 ">
                                    <div className="cursor-pointer	">
                                            <img src="./pan.png" width='41px' height='41px' alt="" />
                                    </div>
                                    <div className="text-[white] text-[15px] sp520min:text-[17px] font-bold  ">
                                        Trade Now
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="pt-[100px] text-[white] font-semibold text-[20px] flex justify-center ">
                        EXCHANGES
                    </div>
                    <div className=" pt-[20px] text-[white] font-semibold text-[20px] hidden md:flex flex-wrap justify-center ">
                        <div className=" px-[10px] justify-center flex items-center pt-[15px]">
                            <img src="./base2/pancake.png" width='189px' height='41px' alt="" />
                        </div>
                        <div className=" px-[10px] justify-center flex items-center pt-[15px]">
                            <img src="./newIcon/bitrue.png" width='110px' height='38px' alt="" />
                        </div>
                        {/* <div className=" px-[10px] justify-center flex items-center pt-[18px]">
                            <img src="./newIcon/3.png" width='112px' height='32' alt="" />
                        </div>
                        <div className=" px-[10px] justify-center flex items-center pt-[18px]">
                            <img src="./newIcon/myria.png" width='70px' height='22px' alt="" />
                        </div>
                        <div className=" px-[10px] justify-center flex items-center pt-[18px]">
                            <img src="./newIcon/petto.png" width='111px' height='38px' alt="" />
                        </div> */}
                        
                        
                    </div>
                    <div className=" pt-[20px] text-[white] font-semibold text-[20px] flex md:hidden  flex-wrap justify-center ">
                        <div className=" px-[10px] justify-center flex items-center pt-[18px]">
                            <img src="./base2/pancake.png" width='120px' height='26px' alt="" />
                        </div>
                        <div className=" px-[10px] justify-center flex items-center pt-[18px]">
                            <img src="./newIcon/bitrue.png" width='69px' height='23px' alt="" />
                        </div>
                        {/* <div className=" px-[10px] justify-center flex items-center pt-[18px]">
                            <img src="./newIcon/3.png" width='71px' height='20' alt="" />
                        </div>
                        <div className=" px-[10px] justify-center flex items-center pt-[18px]">
                            <img src="./newIcon/myria.png" width='50px' height='22px' alt="" />
                        </div>
                        <div className=" px-[10px] justify-center flex items-center pt-[18px]">
                            <img src="./newIcon/petto.png" width='70px' height='22px' alt="" />
                        </div> */}
                    
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Smart;
import cn from 'classnames';
import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";

const Header = ({ otherUrl }) => {

  const [menuOpened, openMenu] = useState(false);
  const [subDocs, setSubDOcs] = useState(false);
  const [subOverview, setSubOverview] = useState(false);

  const closeOpenMenu = () => {
    openMenu(false);
  }
  const OpenMenu = () => {
    openMenu(true);
  }
  const openRank = () => {
    window.open(window.location.origin + "/ranking");
  }
  const openMarketplace = () => {
    window.open("https://marketplace.goldefy.com/");
  }
  const openRankMobile = () => {
    closeOpenMenu();
    let options = 'top=100, left=650, width=1000, height=815, status=no, menubar=no, toolbar=no, resizable=no';
    window.open(window.location.origin + "/ranking", '_blank', options);

  }


  return (
    <div className="fixed top-[0px] pt-[20px] h-[70px] flex justify-between items-center z-50 w-screen pl-[20px] sp1100min:pl-[60px] pr-[20px] gradient_color mb-[30px] ">
      <div className="z-50 hidden sp1100min:flex select-none	">
        <Link to='/'><img src={otherUrl ? `../logo.png` : `./logo.png`} width='167px' alt="" /></Link>
      </div>
      <div className="z-50 flex sp1100min:hidden justify-center items-center select-none	">
        <Link to='/'><img src={otherUrl ? `../logo.png` : `./logo.png`} className="image-logo-size" alt="" /></Link>
      </div>
      <div className={cn("flex items-center  z-30 sp1100min:items-baseline  sp1100min:pr-[50px]  ",)}>
        <div className=" z-50 pr-[20px] hidden sp1100min:flex text-[20px] ">
          <div className="text-[white] px-[10px] text-[20px] cursor-pointer select-none	"><Link to='/'>Home</Link> </div>
          <div className={cn("text-[white] px-[3px] text-[20px] flex  flex-col mx-[0px] cursor-pointer item-header relative")} >
            <div className='pl-[10px] flex justify-around items-center w-[100px] select-none	'>
              <div className='flex items-center justify-center'>
                Docs
              </div>
              <div className='flex items-center justify-center'>
                <img src="../arrow-down.png" width={10} height={10} alt="arrow icon" />
              </div>
            </div>
            <ul class="dropdown">
              <li><a href="https://docs.goldefy.com/" >Lite Paper</a></li>
              <li><a href='https://www.certik.com/projects/goldefy'>Audit</a></li>
            </ul>
          </div>
          <div className="text-[white] px-[10px] text-[20px] cursor-pointer select-none	" onClick={openRank}>Rankings</div>
          <div className={cn("text-[white] px-[10px] text-[20px] flex flex-col  cursor-pointer mx-[0px] item-header relative")}>
            <div className='pl-[10px] flex items-center justify-around w-[124px]'>
              <div className='flex items-center justify-center select-none	select-none	'>
                Overview
              </div>
              <div className='flex items-center justify-center select-none	'>
                <img src="../arrow-down.png" width={10} height={10} alt="arrow icon" />
              </div>
            </div>
            <ul class="dropdown">
              <li><a href="#roadmap">Roadmap</a></li>
              <li><a href="#partners">Partner</a></li>
              <li><a href="#footer">Contact</a></li>
            </ul>
          </div>
          <div className="text-[white] px-[10px] text-[20px] cursor-pointer select-none	"><a href="https://staking.goldefy.com/">Staking</a></div>
          <div className="text-[white] px-[10px] text-[20px] mx-[0px] cursor-pointer select-none	"><a href="https://vesting.goldefy.com/"> Vesting</a></div>
        </div>
        {/* <div className="text-[white] px-[10px] text-[15px] hidden sp1100min:flex marketplace_btn justify-center items-center cursor-pointer select-none	" onClick={openMarketplace}>Marketplace</div>
        <div className="text-[white] px-[10px] mr-[10px] text-[13px] flex sp1100min:hidden marketplace_btn_mobile justify-center items-center cursor-pointer select-none	" onClick={openMarketplace}>Marketplace</div> */}
        <div className={cn("flex sp1100min:hidden items-center pl-[5px] pt-[3px]", menuOpened && 'hidden')}
          onClick={() => OpenMenu()}
        >
          <img src={otherUrl ? `../burger.png` : `./burger.png`} width='31px' alt="" />
        </div>
        <div className={cn("flex sp1100min:hidden items-center pl-[5px] pt-[3px]", !menuOpened && 'hidden')}
          onClick={() => closeOpenMenu()}
        >
          <img src={otherUrl ? `../close.png` : `./close.png`} width='31px' alt="" />
        </div>
      </div>
      <div className={cn('z-[10px] flex flex-col items-center   fixed w-[100%] h-screen left-0 top-[0px] pt-[200px] bg-[#000000DB] ease-in-out duration-300  sp1100min:hidden select-none	', !menuOpened ? "-translate-x-full" : "translate-x-0")}>
        <div className="text-[white] px-[10px] text-[20px] py-[8px] cursor-pointer select-none	" onClick={() => closeOpenMenu()}><Link to='/'>Home</Link></div>
        <div className={cn("text-[white] px-[10px] text-[20px] py-[8px] flex flex-col items-center justify-center text-center ")}>
          <div className='cursor-pointer flex items-center justify-around w-[80px] select-none	' onClick={() => setSubDOcs(!subDocs)} >
            <div className='flex items-center justify-center pl-[7px] select-none	'>
              Docs
            </div>
            <div className='flex items-center justify-center'>
              <img src="../arrow-down.png" width={10} height={10} alt="arrow icon" />
            </div>
          </div>
          <div className={cn('pl-[10px] ease-in-out duration-300 text-[#56e8ff]', !subDocs && 'hidden')}>
            <ul>
              <div className=' text-[15px] py-[15px] ' onClick={() => closeOpenMenu()}>
                <a href="https://docs.goldefy.com/" >Lite Paper</a>
              </div>
              <div className=' text-[15px]' onClick={() => closeOpenMenu()}>
                <a href='https://www.certik.com/projects/goldefy'>Audit</a>
              </div>

            </ul>
          </div>
        </div>
        <div className="text-[white] px-[10px] text-[20px] py-[8px] cursor-pointer select-none	" onClick={() => openRankMobile()}>Rankings </div>
        <div className={cn("text-[white] px-[10px] text-[20px] py-[8px] flex flex-col ", subOverview && 'ml-[23px] ')}   >
          <div className='cursor-pointer flex  items-center justify-center ' onClick={() => setSubOverview(!subOverview)} >
            <div className='flex items-center justify-center pl-[7px] w-[100px] select-none	'>
              Overview
            </div>
            <div className='flex items-center justify-center'>
              <img src="../arrow-down.png" width={10} height={10} alt="arrow icon" />
            </div>
          </div>
          <div className={cn('pl-[20px]  text-[#56e8ff] text-[15px]', !subOverview && 'hidden')} >
            <ul>
              <div className='pt-[10px]' onClick={() => closeOpenMenu()}>
                <a href="#roadmap"> Roadmap</a>
              </div>
              <div className='py-[15px]' onClick={() => closeOpenMenu()}>
                <a href="#partners"> Partner</a>
              </div>
              <div className='' onClick={() => closeOpenMenu()}>
                <a href="#footer"> Contact</a>
              </div>
            </ul>
          </div>
        </div>
        <div className="text-[white] px-[10px] text-[20px] py-[8px] cursor-pointer select-none" onClick={() => closeOpenMenu()}><a href="https://staking.goldefy.com/">Staking</a></div>
        <div className="text-[white] px-[10px] text-[20px] py-[8px] cursor-pointer select-none" onClick={() => closeOpenMenu()}><a href="https://vesting.goldefy.com/"> Vesting</a></div>
      </div>
    </div>


  );

}

export default Header;

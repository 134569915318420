

const MemberCard =({img ,name ,role ,linkedin})=>{
    return(
        <div className="flex flex-col items-center justify-center px-[5px] md:px-[10px] py-[20px]">
            <div className="hidden md:flex">
                <img className="rounded-[20px]" src={`./members/${img}`} width='200px' height='188px' alt="" />
            </div>
            <div className="flex md:hidden">
                <img className="rounded-[20px]" src={`./members/${img}`} width='105px' height='98px' alt="" />
            </div>
            <div className="flex justify-between px-[8%] w-[105px]  md:items-start md:w-[200px] pt-[5px] md:pt-[12px]">
                <div className="flex flex-col justify-center items-start  ">
                    <div className="text-white text-[10px] md:text-[16px] ">
                        {name}
                    </div>
                    <div className=" text-[#51C9FF] text-[8px] md:text-[10px] ">
                        {role}
                    </div>
                </div>
                <div className="hidden md:flex">
                    <a href={linkedin} target="_blank" > <img src="./Linkeden.png" width='20px' height='20px'  alt="" />  </a>
                </div>
                <div className="flex justify-center items-start md:hidden">
                    <a href={linkedin} target="_blank" > <img src="./Linkeden.png" width='17px' height='17px'  alt="" />  </a>
                </div>
            </div>
        </div>
    )
}

export default MemberCard ;
import React, { useEffect, useState } from "react";
import ReactCountryFlag from "react-country-flag"
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css' ;

const IndividualRank =({item})=>{
 
    const [isWidth , setWidth]=useState() ;
    useEffect(()=>{
        (window.innerWidth > 768) ? setWidth(true) : setWidth(false) ;
        // console.log(item . item.rank) ;
    },[]) ;

    const SetGmail = (mail) =>{
        if(mail.length <30) return mail ;
        return (mail.slice(0,10) + '...' + mail.slice(mail.length -10 , mail.length)) ;
    }

    return item && (
        <>
                <div className="individualOneDesktop">
                
                    <div className="mainTableFlex"  style={{width:'6%'}}>
                        {item.rank == 1 && <img className="rankImg" src="./img/medal_gold.png" alt="crownGold" />}
                        {item.rank == 2 && <img className="rankImg" src="./img/medal_silver.png" alt="medal_silver" />}
                        {item.rank == 3 && <img className="rankImg" src="./img/medal_bronze.png" alt="medal_bronze" />}
                        {item.rank >= 4 &&  item.rank}
                    </div>
                    <div className="mainTableFlex" style={{width:'10%'}}> {<ReactCountryFlag countryCode={item.country} svg />}</div>
                    <div className="mainTableFlex" style={{width:'15%'}}>{item.nick_name}</div>
                    <div className="mainTableFlex" style={{width:'21%'}}>{SetGmail(item.email)}</div>
                    <div className="mainTableFlex" style={{width:'9%'}}>{item.lucky}</div>
                    <div className="mainTableFlex" style={{width:'10%'}}>{item.Tgame}</div>
                    <div className="mainTableFlex" style={{width:'10%'}}>{item.goldy}</div>
                    <div className="mainTableFlex" style={{width:'15%'}}>{item.total}</div>
                </div>
                
                <div  className="individualOneMobile mobileSetSize">

                    <div className="mainTableFlex" style={{width:'15%'}}>
                        {item.rank == 1 && <img className="rankImg" src="./img/medal_gold.png" alt="crownGold" />}
                        {item.rank == 2 && <img className="rankImg" src="./img/medal_silver.png" alt="medal_silver" />}
                        {item.rank == 3 && <img className="rankImg" src="./img/medal_bronze.png" alt="medal_bronze" />}
                        {item.rank >= 4 &&  item.rank}
                    </div>
                    <div className="mainTableFlex" style={{width:'15%'}}>{<ReactCountryFlag countryCode={item.country} svg />}</div>
                    <div className="mainTableFlex mobileSetSize"  style={{width:'35%'}}>{item.nick_name}</div>
                    <div className="mainTableFlex mobileSetSize"  style={{width:'15%'}}>{item.lucky}</div>
                    <div className="mainTableFlex mobileSetSize"  style={{width:'15%'}}>{item.total}</div>
                </div>
            
        </>
    )
}

export default IndividualRank ;
import CustomProgressbar from "./CustomProgressbar" ;
import { useEffect, React ,useState,Fragment } from 'react' ;

const Roadmap =()=>{

    useEffect(()=>{
        const ele = document.getElementById('roadmap') ;
        // ele.scrollIntoView() ;
    },[]) ;

    return(
        <div className="roadmap_background overflow-hidden select-none	" id='roadmap'>
            <div className="text-[white] text-[60px] flex justify-center font-bold text-center items-center pt-[69px]">
                Roadmap
            </div>
            <div className="overflow-scroll	 lg:overflow-hidden	 horizontalskeletons">
                <div className="relative mt-[184px] flex justify-center min-w-[1220px] " >
                    
                    <div className="absolute min-w-[1200px] w-[1200px] sp1600min:w-[1600px] left-[50%] -translate-x-1/2 top-[30px]	z-0 ">
                        <CustomProgressbar />
                    </div>
                    <div className="flex justify-evenly  w-[1200px] sp1600min:w-[1600px] " >
                        {/* ph1 */}

                        <div className="flex flex-col items-start  z-10" id="ph1">
                            
                            <div className="flex flex-col items-center items ">
                                <div className="enable_qq flex justify-center items-center mt-[0px]  ">
                                    <img src="./4.png" width='34px' height='34px'  alt="" />
                                </div>
                                <div className="text-[17px] text-white font-bold enable_btn flex justify-center items-center mt-[30px] ">
                                    Q3 / 2021
                                </div>
                                
                            </div>
                            <div className="flex flex-col justify-start max-w-[225px] min-w-[225px] ">
                                <div className="mb-[12px] mt-[29px] flex items-center text-white text-[12px] font-semibold  ">
                                    <div className="mr-[9px]">
                                        <img  src="./Diamond.png" width='16px' height='13px' alt="" />
                                    </div>
                                    <div className="">
                                        Team Forming 
                                    </div>
                                </div>
                                <div className="mb-[12px] flex items-center text-white text-[12px] font-semibold  ">
                                    <div className="mr-[9px]">
                                        <img  src="./Diamond.png" width='16px' height='13px' alt="" />
                                    </div>
                                    <div className="">
                                        Start Development 
                                    </div>
                                </div>
                                <div className="mb-[12px] flex items-center text-white text-[12px] font-semibold  ">
                                    <div className="mr-[9px]">
                                        <img  src="./Diamond.png" width='16px' height='13px' alt="" />
                                    </div>
                                    <div className="">
                                        Build Game Concept
                                    </div>
                                </div>
                            
                                
                            </div>
                        </div>

                        {/* ph2 */}

                        <div className="flex flex-col items-start  z-10 mx-[20px]" id="ph2">
                            <div className="flex flex-col items-center items">
                                <div className="enable_qq flex justify-center items-center  ">
                                    <img src="./5.png" width='34px' height='34px'  alt="" />
                                </div>
                                <div className="text-[17px] text-white font-bold enable_btn flex justify-center items-center mt-[30px] ">
                                    Q4 / 2021
                                </div>
                                
                            </div>
                            <div className="flex flex-col justify-start max-w-[225px] min-w-[225px] ">
                                <div className="mb-[12px] mt-[29px] flex items-center text-white text-[12px] font-semibold  ">
                                    <div className="mr-[9px]">
                                        <img  src="./Diamond.png" width='16px' height='13px' alt="" />
                                    </div>
                                    <div className="">
                                        Smart contracts and interaction setup
                                    </div>
                                </div>
                                <div className="mb-[12px] flex items-center text-white text-[12px] font-semibold  ">
                                    <div className="mr-[9px]">
                                        <img  src="./Diamond.png" width='16px' height='13px' alt="" />
                                    </div>
                                    <div className="">
                                        Theme and Art Creation 
                                    </div>
                                </div>
                                <div className="mb-[12px] flex items-center text-white text-[12px] font-semibold  ">
                                    <div className="mr-[9px]">
                                        <img  src="./Diamond.png" width='16px' height='13px' alt="" />
                                    </div>
                                    <div className="">
                                        Pre-Marketing 
                                    </div>
                                </div>
                            
                                
                            </div>
                        </div>

                    {/* ph3 */}
                        <div className="flex flex-col items-start  z-10" id="ph3">
                            <div className="flex flex-col items-center items ">
                                
                                <div className="enable_qq flex justify-center items-center  ">
                                    <img src="./6.png" width='34px' height='34px'  alt="" />
                                </div>
                                <div className="text-[17px] text-white font-bold enable_btn flex justify-center items-center mt-[30px] ">
                                    1H / 2022
                                </div>
                                
                            </div>
                            <div className="flex flex-col justify-start max-w-[225px] min-w-[225px] ">
                                <div className="mb-[12px] mt-[29px] flex items-center text-white text-[12px] font-semibold  ">
                                    <div className="mr-[9px]">
                                        <img  src="./Diamond.png" width='16px' height='13px' alt="" />
                                    </div>
                                    <div className="">
                                        Pre-Mining APP release
                                    </div>
                                </div>
                                <div className="mb-[12px] flex items-center text-white text-[12px] font-semibold  ">
                                    <div className="mr-[9px]">
                                        <img  src="./Diamond.png" width='16px' height='13px' alt="" />
                                    </div>
                                    <div className="">
                                        GOldeFy Token Lanuch IDO cex listing
                                    </div>
                                </div>
                                <div className="mb-[12px] flex items-center text-white text-[12px] font-semibold  ">
                                    <div className="mr-[9px]">
                                        <img  src="./Diamond.png" width='16px' height='13px' alt="" />
                                    </div>
                                    <div className="">
                                        Live server Expansion
                                    </div>
                                </div>
                                <div className="mb-[12px] flex items-center text-white text-[12px] font-semibold  ">
                                    <div className="mr-[9px]">
                                        <img  src="./Diamond.png" width='16px' height='13px' alt="" />
                                    </div>
                                    <div className="">
                                        NFT pet airdrop event
                                    </div>
                                </div>
                                <div className="mb-[12px] flex items-center text-white text-[12px] font-semibold  ">
                                    <div className="mr-[9px]">
                                        <img  src="./Diamond.png" width='16px' height='13px' alt="" />
                                    </div>
                                    <div className="">
                                        Alpha Test
                                    </div>
                                </div>
                                <div className="mb-[12px] flex items-center text-white text-[12px] font-semibold  ">
                                    <div className="mr-[9px]">
                                        <img  src="./Diamond.png" width='16px' height='13px' alt="" />
                                    </div>
                                    <div className="">
                                        Leaderboard system integration
                                    </div>
                                </div>
                                
                                
                            </div>
                        </div>
                    {/* ph4 */}
                        <div className="flex flex-col items-start  z-10 mx-[20px]" id="ph4">
                            <div className="flex flex-col items-center items -mt-[60px]">
                                <img src="./arrow.png" width='45px' height='62px' alt="" />
                                <div className="enable_qq flex justify-center items-center  ">
                                    <img src="./7.png" width='34px' height='34px'  alt="" />
                                </div>
                                <div className="text-[17px] text-white font-bold enable_btn flex justify-center items-center mt-[30px] ">
                                    2H / 2022
                                </div>
                                
                            </div>
                            <div className="flex flex-col justify-start max-w-[225px] min-w-[225px]">
                                <div className="mb-[12px] mt-[29px] flex items-center text-white text-[12px] font-semibold  ">
                                    <div className="mr-[9px]">
                                        <img  src="./Diamond.png" width='16px' height='13px' alt="" />
                                    </div>
                                    <div className="">
                                        Metaverse Beta Test
                                    </div>
                                </div>
                                <div className="mb-[12px] flex items-center text-white text-[12px] font-semibold  ">
                                    <div className="mr-[9px]">
                                        <img  src="./Diamond.png" width='16px' height='13px' alt="" />
                                    </div>
                                    <div className="">
                                        Mobile APK release
                                    </div>
                                </div>
                                <div className="mb-[12px] flex items-center text-white text-[12px] font-semibold  ">
                                    <div className="mr-[9px]">
                                        <img  src="./Diamond.png" width='16px' height='13px' alt="" />
                                    </div>
                                    <div className="">
                                        Launch Phase 1 metaverse
                                    </div>
                                </div>
                                <div className="mb-[12px] flex items-center text-white text-[12px] font-semibold  ">
                                    <div className="mr-[9px]">
                                        <img  src="./Diamond.png" width='16px' height='13px' alt="" />
                                    </div>
                                    <div className="">
                                        Mini-games Launch
                                    </div>
                                </div>
                                <div className="mb-[12px] flex items-center text-white text-[12px] font-semibold  ">
                                    <div className="mr-[9px]">
                                        <img  src="./Diamond.png" width='16px' height='13px' alt="" />
                                    </div>
                                    <div className="">
                                        Marketplace Phase 1 Launch
                                    </div>
                                </div>
                                <div className="mb-[12px] flex items-center text-white text-[12px] font-semibold  ">
                                    <div className="mr-[9px]">
                                        <img  src="./Diamond.png" width='16px' height='13px' alt="" />
                                    </div>
                                    <div className="">
                                        GoldyPot Launch
                                    </div>
                                </div>
                                <div className="mb-[12px] flex items-center text-white text-[12px] font-semibold  ">
                                    <div className="mr-[9px]">
                                        <img  src="./Diamond.png" width='16px' height='13px' alt="" />
                                    </div>
                                    <div className="">
                                        Goldypass release
                                    </div>
                                </div>
                                <div className="mb-[12px] flex items-center text-white text-[12px] font-semibold  ">
                                    <div className="mr-[9px]">
                                        <img  src="./Diamond.png" width='16px' height='13px' alt="" />
                                    </div>
                                    <div className="">
                                        GoldyPet NFT release
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    {/* ph5 */}
                        <div className="flex flex-col items-start  z-10" id="ph5">
                            <div className="flex flex-col items-center items">
                                <div className="disable_qq flex justify-center items-center  ">
                                    <img src="./8.png" width='34px' height='34px'  alt="" />
                                </div>
                                <div className="text-[17px] text-white font-bold disable_btn flex justify-center items-center mt-[30px] ">
                                    1H / 2023
                                </div>
                                
                            </div>
                            <div className="flex flex-col justify-start max-w-[225px] min-w-[225px] ">
                                <div className="mb-[12px] mt-[29px] flex items-center text-white text-[12px] font-semibold  ">
                                    <div className="mr-[9px]">
                                        <img  src="./Diamond.png" width='16px' height='13px' alt="" />
                                    </div>
                                    <div className="">
                                        Update Features in metaverse
                                    </div>
                                </div>
                                <div className="mb-[12px] flex items-center text-white text-[12px] font-semibold  ">
                                    <div className="mr-[9px]">
                                        <img  src="./Diamond.png" width='16px' height='13px' alt="" />
                                    </div>
                                    <div className="">
                                        Update live server expansion
                                    </div>
                                </div>
                                
                                
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Roadmap ;
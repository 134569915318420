// import logo from './logo.svg';
import './App.css';
// import Header from './Header' ;
import Home from './pages/Home' ;
import Privacy from './pages/Footer/Privacy' ;
import Term from './pages/Footer/Term' ;
import Goldypot from './pages/Goldypot' ;
import ForgotPass from './pages/forgotPass/forgotPass' ;
// import * as React from "react";
import RankMain from './pages/ranking/RankMain'
import CookieConsent, { Cookies,getCookieConsentValue ,resetCookieConsentValue } from "react-cookie-consent";
import React, { useEffect, useState } from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Routes
} from "react-router-dom";
import Guide from './pages/guide';
import Disclaimer from './pages/disclaimer';
import BackToTop from './pages/BackToTopBtn';
function App() {


  useEffect(()=>{
    // console.log(getCookieConsentValue()) ;
    // console.log(resetCookieConsentValue() , 'reset') ;
  },[]) ;

  return (
    
      <div>

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/privacyPolicy" element={<Privacy />} />
          <Route path="/guide" element={<Guide />} />
          <Route path="/disclaimer" element={<Disclaimer />} />
          <Route path="/term" element={<Term />}  />
          <Route path="/ranking" element={<RankMain />}  />
          <Route path="/forget_password" >
            <Route path=":email" element={<ForgotPass />} />
          </Route>
          
        </Routes>

        <div className="desktopCookie">
          <CookieConsent
            // location="bottom"
            buttonText="Agree"
            cookieName="goldefyCookie"
            style={{ background: "white" ,left:'100%',bottom:'10px' , transform:'translate(-105% , -6%)' , color:'black' , width:'350px' ,alignItems:'flex-end'  , flexDirection:'column' , justifyContent:'flex-end' }}
            buttonStyle={{ color: "#282828" ,width:'100px',height:'38px', backgroundColor:'rgb(68 111 192)', fontSize: "17px" }}
            contentStyle={{flex:'0' , height:'102px'}}
            expires={150}
          >
            <div className='flex flex-col'>
              <div className="font-semibold text-[18px] pb-[5px]">
                Cookies & Privacy Policy
              </div>
              <div className="-mb-[13px]">
                We use browser cookies to personalize content and Ads, to provide social media features and analyse traffic. To use our site, you must agree to our Pricacy Policy.
              </div>
            </div>  
          </CookieConsent>  
        </div>
        <div className="mobileCookie">
          <CookieConsent
            // location="bottom"
            buttonText="Agree"
            cookieName="goldefyCookie"
            style={{ background: "white" ,left:'50%',bottom:'10px' , transform:'translate(-50% , -3%)' , color:'black' , width:'315px' ,alignItems:'flex-end'  , flexDirection:'column' , justifyContent:'flex-end' }}
            buttonStyle={{ display:'flex' , justifyContent:'center' , alignItems:'center' , color: "#282828" ,width:'90px',height:'30px', backgroundColor:'rgb(68 111 192)', fontSize: "15px" }}
            contentStyle={{flex:'0' , height:'102px'}}
            expires={150}
          >
            <div className='flex flex-col'>
              <div className="font-semibold text-[16px] pb-[5px]">
                Cookies & Privacy Policy
              </div>
              <div className="-mb-[23px] text-[13px]">
                We use browser cookies to personalize content and Ads, to provide social media features and analyse traffic. To use our site, you must agree to our Pricacy Policy.
              </div>
            </div>  
          </CookieConsent>
          
        </div>
        <BackToTop/>
      </div>
  );
}

export default App;

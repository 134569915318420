import { useEffect, useRef, useState,Fragment } from 'react';


const Goldypot =()=>{

    return(
        <div className="bg-[#071F4D] overflow-hidden select-none	">
            <div className="text-[white] text-[28px] xl:text-[60px] flex justify-center font-bold text-center items-center ">
                Goldy Pot
            </div>
            <div className=" text-[12px] xl:text-[17px] text-[white] flex flex-col items-center pt-[14px] xl:pt-[33px]">
                <div className="px-[50px] sp1100min:px-[30px] sp1100min:w-[933px] flex justify-center text-center">
                Goldypot is unique in the scalability of NFT's utility by adopting a method in which the money of all sold characters are collected and paid to a specific user as a prize. In addition, characters that did not win have the advantage of increasing their value through enhancement and breeding.
                </div>
            </div>
            <div className="hidden justify-around  bg-[black] mt-[47px] pl-[154px] xl:flex ">
                <div className="flex flex-col justify-center items-center z-10">
                        <img src="./goldslot.png" width="789px" height="474px" alt="" />
                        <div className='text-[white] text-[16px] text-center w-[468px] pt-[10px] '>
                            Goldypot number is automatically selected randomly when purchasing goldy pass or goldy NFT.
                        </div>
                </div>
                <div className="-ml-[100px]">
                    <video width="1126px" height="633px" autoPlay muted loop>
                        <source src= "./goldypot.mp4" type="video/mp4" />
                    </video>
                </div>
            </div>

            <div className="flex xl:hidden justify-around flex-col-reverse items-cetner xl:bg-[black] mt-[47px]  ">
                <div className="flex flex-col justify-center items-center z-10 mt-[20px]">
                        <img src="./goldslot.png" width="378px" height="378px" alt="" />
                        <div className='text-[white] text-[16px] text-center w-[300px] pt-[10px] '>
                            Goldypot number is automatically selected randomly when purchasing goldy pass or goldy NFT.
                        </div>
                </div>
                <div className=" bg-[black] xl:bg-transparent flex items-center justify-center ">
                    <video width="600px" height="433px" autoPlay muted loop>
                        <source src= "./goldypot.mp4" type="video/mp4" />
                    </video>
                </div>
            </div>
            <div className='bg-[#071F4D] h-[100px] '>
                
            </div>
        </div>
    )
}

export default Goldypot ;
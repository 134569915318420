

const Certik =()=>{

    return(
        <div className="flex flex-col items-center  bg-[#071F4D] overflow-hidden sp1100min:pt-[50px] ">
            <img src= "./partners/certik.svg"  alt="" />
            <div className="text-[white] text-[12px] mt-[13px] pb-[150px] ">
                GOD-Token smart contract is audited by Certik.
            </div>
        </div>
    )
}

export default Certik ;
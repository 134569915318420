import './rank.css';

import RankingTable from './RankingTable';
import { useCountdown } from '../../hooks/useCountdown';
import React, { useRef , useEffect, useState } from "react";
import axios from 'axios' ;

const RankMain =()=>{
    const [isNewSeason , setNewSeason] = useState(false) ;
    const [isRemainTime, setRemainTime] = useState(24 * 3600 * 1000) ;
    const [searchKey, setSearchKey] = useState() ;
    const { innerWidth: width, innerHeight: height } = window;
    const [endDate, setEndDate] = useState(new Date());
    const [isShow, setIsShow] = useState(false);

    const getTournament = async () =>{
        try{
            const resp = await axios.get('/minigame/getTimeCountDown') ;
            setIsShow(false);
            if(resp.data.status) {
                const defaultDate = 9999;
                const responseDate = new Date(resp.data.DATA[0].tournament_end).getFullYear();
                if(defaultDate != responseDate) {
                    setEndDate(resp.data.DATA[0].tournament_end);
                    setIsShow(true);
                }
			}
        }catch(e){
            setIsShow(false);
        }
    }

    const searchDesktop =(e)=>{
        setSearchKey(e.target.value) ;
    }

    useEffect(()=>{
        getTournament();
    },[]) ;

    const [days, hours, minutes, seconds] = useCountdown(new Date(endDate));
    
    useEffect(() => {
		if(days < 0 || hours < 0 || minutes < 0 || seconds < 0) {
			setIsShow(false)
		}
	}, [days, hours, minutes, seconds]);

    const twoDigits = (value) => {
		if (value.toString().length <= 1) {
			return '0' + value.toString();
		}
		if (value) return value.toString();
		return 0
	}

    return(
        <div className='backImg' style={{overflow:'auto'}} >
            <div className='main'>
                <div className='mainHeader'>
                    {isShow && <div className='countdown-container'>
                        <div className='countdown-time'>{twoDigits(hours)}H</div>
                        <div className='countdown-time'>{twoDigits(minutes)}M</div>
                        <div className='countdown-time'>{twoDigits(seconds)}S</div>
                    </div>}
                
                    <div className='searchheader'>
                        <img className="searchImgDesktop" src="./android_img/search.png"/>
                        <input type="text" className="inputSearchDesktop" onChange={(e)=>searchDesktop(e)} />
                    </div>
                </div>
                
                <div className='mainTable'>
                    <RankingTable searchKey = {searchKey}/>
                </div>
            </div>
        </div>
    )
}

export default RankMain ;

import React, { useEffect, useState } from "react";
import IndividualRank from "./IndividualRank";
import ReactCountryFlag from "react-country-flag" ;
import axios from 'axios' ;
import { PulseLoader } from 'react-spinners' ;

const RankingTable =({searchKey})=>{
    const [isWidth , setWidth]=useState() ;
    const [currentDatas , setCurrentDatas] = useState([]) ;
    const [OriginDatas , setOriginDatas] = useState([]) ;
    const [isloading , setLoading] = useState(false) ;
    
    const getRankData = async () =>{
        try{
              const resp = await axios.get('/minigame/getNowTournamentRank') ;
            //   console.log(resp.data.status,'ddd') ;
              setCurrentDatas(resp.data.DATA) ;
              let tmp = resp.data.DATA ;
              for (let i = 0 ; i < resp.data.DATA.length ; i ++ ) {
                tmp[i].rank = i+1 ;
              }
              setOriginDatas(tmp) ;
              setLoading(true) ;
        }catch(e){
            // console.log('error ') ;
        }
    }

    useEffect(()=>{
        (window.innerWidth > 768) ? setWidth(true) : setWidth(false) ;
        getRankData() ;
    },[]) ;
    useEffect(()=>{
        if(searchKey) {
            setLoading(false) ;
            let rlt = OriginDatas.filter(tmp => ( tmp.email?.includes(searchKey) || tmp.lucky?.includes(searchKey) || tmp.nick_name?.includes(searchKey))) ;
            setCurrentDatas(rlt) ;
            setTimeout(()=>{setLoading(true) ;} , 500) ;
        }
    },[searchKey]) ;

    const searchMobile=(e)=>{
        setLoading(false) ;
        let rlt = OriginDatas.filter(tmp => (tmp.email?.includes(e.target.value) || tmp.lucky?.includes(e.target.value) || tmp.nick_name?.includes(e.target.value))) ;
        setCurrentDatas(rlt);
        setTimeout(()=>{setLoading(true) ;} , 500) ;
    }

    return (
        <div className="main_tab">
            
                <div className="rankingMobileFilter">
                    <div className="mobileName">Rankings</div>
                    <div className="searchMobile">
                        <img className="searchImgMobile" src="./android_img/search.png"/>
                        <input type="text" className="inputSearchMobile" onChange={(e)=>searchMobile(e)}/>
                    </div>
                </div>
                <div className="DesktopHeader">
                    <div className="tableHeader">
                        <div className="headerResponse" style={{width:'10%'}}>
                            <img className="imgHeader" src="./img/crown_gold.png" alt="crownGold" />
                            <div className="HeaderTitle"><br/>RANK</div>
                        
                        </div>
                        <div className="headerResponse" style={{width:'10%'}}>
                            <img className="imgHeader" src="./img/country.png" alt="crownGold" />
                            <div className="HeaderTitle"><br/>COUNTRY</div>
                        
                        </div>
                        <div className="headerResponse" style={{width:'15%'}}>
                            <img className="imgHeader" src="./img/blank.png" alt="crownGold" />
                            <div className="HeaderTitle"><br/>NICK NAME</div>

                        </div>
                        <div className="headerResponsenon" style={{width:'20%'}}>
                            <img className="imgHeader" src="./img/letter_0.png" alt="crownGold" />
                            <div className="HeaderTitle"><br/>EMAIL</div>
                        
                        </div>
                        <div className="headerResponse" style={{width:'10%'}}>
                            <img className="imgHeader" src="./img/ticket.png" alt="crownGold" />
                            <div className="HeaderTitle">LUCKY<br/>NUMBER</div>

                        </div>
                        <div className="headerResponsenon" style={{width:'10%'}}>
                            <img className="imgHeader" src="./img/2048.png" alt="crownGold" />
                            <div className="HeaderTitle">2048<br/>SCORE</div>
                        
                        </div>
                        <div className="headerResponsenon" style={{width:'10%'}}>
                            <img className="imgHeader" src="./img/goldycrossing.png" alt="crownGold" />
                            <div className="HeaderTitle">GOLDY<br/>CROSSING</div>
                        
                        </div>
                        <div className="headerResponse" style={{width:'15%'}}>
                            <img className="imgHeader" src="./img/gold.png" alt="crownGold" />
                            <div className="HeaderTitle"><br/>TOTAL SCORE</div>
                        
                        </div>
                    </div>
                </div>
                <div className="MobileHeader">
                    <div className="tableHeader">
                        <div className="headerResponse" style={{width:'16%'}}>
                            <img className="imgHeader" src="./img/crown_gold.png" alt="crownGold" />
                            <div className="HeaderTitle">RANK<br/></div>
                        
                        </div>
                        <div className="headerResponse" style={{width:'16%'}}>
                            <img className="imgHeader" src="./img/country.png" alt="crownGold" />
                            <div className="HeaderTitle">COUNTRY<br/></div>
                        
                        </div>
                        <div className="headerResponse" style={{width:'30%'}}>
                            <img className="imgHeader" src="./img/blank.png" alt="crownGold" />
                            <div className="HeaderTitle">NICK NAME</div>

                        </div>
                        <div className="headerResponse" style={{width:'16%'}}>
                            <img className="imgHeader" src="./img/ticket.png" alt="crownGold" />
                            <div className="HeaderTitle">LUCKY<br/>NUMBER</div>

                        </div>
                        <div className="headerResponse" style={{width:'16%'}}>
                            <img className="imgHeader" src="./img/gold.png" alt="crownGold" />
                            <div className="HeaderTitle">TOTAL<br/>SCORE</div>
                        
                        </div>
                    </div>
                </div>
            
            
            <div className="tableHeaderTitle">
            </div>
            <div className="tableBody" styles={{position:'relative'}}>
                {
                   isloading && currentDatas && currentDatas.map((item ,inx)=>(
                        <IndividualRank
                            item ={item}
                            
                            key = {inx}
                        />
                    ))
                }
                {
                    !isloading &&
                    <PulseLoader 
                        cssOverride={{position:'absolute' , left:'50%' , top:'50%'}}
                        color="#b7661d"
                        height={4}
                        width={80}
                    />
                }
            </div>
            
        </div>
    )
}

export default RankingTable ;
import React from "react";

import ProgressBar from "@ramonak/react-progress-bar";

const CustomProgressbar =()=>{
    return (
        <ProgressBar
            completed={50}
            className="wrapper"
            barContainerClassName="container_progressbar"
            completedClassName="barCompleted"
            labelClassName="label"
        />
    )
}

export default CustomProgressbar ;


const Partners =()=>{
    return(
        <div className="overflow-hidden select-none	" id="partners" >
            <div className="bg-[#071F4D] pt-[40px] pb-[150px] " >
                <div className="text-[white] text-[28px] md:text-[60px] flex justify-center font-bold text-center items-center ">
                    Partners
                </div>
                {/* <div className="text-[white] mt-[31px] flex justify-center font-bold text-center items-center ">
                    
                </div> */}
                <div className="hidden md:flex flex-wrap justify-center">
                    <div className="flex justify-center items-center px-[15px]">
                        <a href="https://horizonland.app/" target="_blank" rel="">
                            <img src="./partners/Horizon.png" width='169px' height='85px' alt="" />
                        </a>
                    </div>
                    <div className="flex justify-center items-center px-[15px]">
                        <a href="https://bitcourier.co.uk/news/goldefy-interview" target="_blank" rel="">
                            <img src="./partners/bitcourier.png" width='169px' height='85px' alt="" />
                        </a>
                    </div>
                    <div className="flex justify-center items-center px-[15px]">
                        <a href="https://www.oddiyana.ventures" target="_blank" rel="" >
                            <img src="./partners/oddiyana.png" width='100px' height='58px' alt="" />
                        </a>
                    </div>
                    <div className="flex justify-center items-center px-[15px]">
                        <a href="https://spintop.network/" target="_blank" rel="" >
                            <img src="./partners/spintop.png" width='130px' height='33px' alt="" />
                        </a>
                    </div>
                    <div className="flex justify-center items-center px-[15px]">
                        <a href="https://breach.gg/" target="_blank" rel="">
                            <img src="./partners/breach.png" width='101px' height='36px' alt="" />
                        </a>
                    </div>

                    <div className="flex justify-center items-center px-[15px]">
                        <a href="https://rageeffect.io/" target="_blank" rel="" >
                            <img src="./newIcon/3.png" width='101px' height='36px' alt="" />
                        </a>
                    </div>
                    <div className="flex justify-center items-center px-[15px]">
                        <a href="https://myria.com/" target="_blank" rel="">
                            <img src="./newIcon/myria.png" width='70px' height='36px' alt="" />
                        </a>
                    </div>
                    <div className="flex justify-center items-center px-[15px]">
                        <a href="https://www.petto.io/" target="_blank" rel="">
                            <img src="./newIcon/petto.png" width='101px' height='36px' alt="" />
                        </a>
                    </div>

                    <div className="flex justify-center items-center px-[15px]">
                        <a href="https://coincodex.com/" target="_blank" rel="">
                            <img src="./partners/coindex.jpg" width='130px' height='25px' alt="" />
                        </a>
                    </div>
                    <div className="flex justify-center items-center px-[15px]">
                        <a href="https://bitkeep.com/" target="_blank" rel="">
                            <img src="./partners/bitkeep.png" width='139px' height='44px' alt="" />
                        </a>
                    </div>
                    <div className="flex justify-center items-center px-[15px]">
                        <a href="https://binstarter.io/" target="_blank" rel="">
                            <img src="./partners/bitstarter.png" width='139px' height='42px' alt="" />
                        </a>
                    </div>
                    <div className="flex justify-center items-center px-[15px]">
                        <a href="https://startfi.io/" target="_blank" rel="">
                            <img src="./partners/startfi.png" width='150px' height='70px' alt="" />
                        </a>
                    </div>
                    <div className="flex justify-center items-center px-[15px]">
                        <a href="https://kwikstarter.org/" target="_blank" rel="">
                            <img src="./partners/kwikstarter.png" width='150px' height='25px' alt="" />
                        </a>
                    </div>
                    <div className="flex justify-center items-center px-[15px]">
                        <a href="https://www.seedthriftventures.com/" target="_blank" rel="">
                            <img src="./partners/seedthrift.png" width='157px' height='111px' alt="" />
                        </a>
                    </div>
                    <div className="flex justify-center items-center px-[15px]">
                        <a href="https://www.dapp-playstore.com/" target="_blank" rel="">
                            <img src="./partners/das.png" width='120px' height='82px' alt="" />
                        </a>
                    </div>
                    <div className="flex justify-center items-center px-[15px]">
                        <a href="https://kryptoplayboycapital.com/" target="_blank" rel="">
                            <img src="./partners/kpc.png" width='125px' height='88px' alt="" />
                        </a>
                    </div>
                    <div className="flex justify-center items-center px-[15px]">
                        <a href="https://whitelistventures.com/" target="_blank" rel="">
                            <img src="./partners/whitelist.png" width='146px' height='41px' alt="" />
                        </a>
                    </div>
                    <div className="flex justify-center items-center px-[15px]">
                        <a href="https://www.fairumventures.com/" target="_blank" rel="">
                            <img src="./partners/fairum.png" width='189px' height='33px' alt="" />
                        </a>
                    </div>
                    <div className="flex justify-center items-center px-[15px]">
                        <a href="https://www.solrdao.com/" target="_blank" rel="">
                            <img src="./partners/solrdao.png" width='121px' height='86px' alt="" />
                        </a>
                    </div>
                    <div className="flex justify-center items-center px-[15px]">
                        <a href="https://www.youtube.com/channel/UCX9zgvx6BLkkuprVd5Zy-Wg" target="_blank" rel="">
                            <img src="./partners/incrypto.png" width='134px' height='94px' alt="" />
                        </a>
                    </div>
                    <div className="flex justify-center items-center px-[15px]">
                        <a href="https://alphahunt.club/" target="_blank" rel="">
                            <img src="./partners/alphahunt.png" width='158px' height='71px' alt="" />
                        </a>
                    </div>
                    <div className="flex justify-center items-center px-[15px]">
                        <a href="https://www.bird.money/" target="_blank" rel="">
                            <img src="./partners/bird.png" width='120px' height='49px' alt="" />
                        </a>
                    </div>
                    <div className="flex justify-center items-center px-[15px]">
                        <a href="https://daostarter.pro/#/" target="_blank" rel="">
                            <img src="./partners/daostater.png" width='157px' height='32px' alt="" />
                        </a>
                    </div>
                    <div className="flex justify-center items-center px-[15px]">
                        <a href="https://vulturepeak.io/" target="_blank" rel="">
                            <img src="./partners/vulturepeak.png" width='82px' height='87px' alt="" />
                        </a>
                    </div>
                    <div className="flex justify-center items-center px-[15px]">
                        <a href="https://battlearena.gg/" target="_blank" rel="">
                            <img src="./partners/battlearena.png" width='75px' height='53px' alt="" />
                        </a>
                    </div>
                    <div className="flex justify-center items-center px-[15px]">
                        <a href="https://supraoracles.com/" target="_blank" rel="">
                            <img src="./partners/supraoracles.png" width='41px' height='58px' alt="" />
                        </a>
                    </div>
                    <div className="flex justify-center items-center px-[15px]">
                        <a href="https://safelaunch.io/" target="_blank" rel="">
                            <img src="./partners/safelaunch.png" width='80px' height='68px' alt="" />
                        </a>
                    </div>
                    <div className="flex justify-center items-center px-[15px]">
                        <a href="https://www.goldefy.com/#" target="_blank" rel="">
                            <img src="./partners/budha.png" width='44px' height='71px' alt="" />
                        </a>
                    </div>
                    <div className="flex justify-center items-center px-[15px]">
                        <a href="https://www.vespertine.capital/" target="_blank" rel="">
                            <img src="./partners/vespertine.png" width='75px' height='74px' alt="" />
                        </a>
                    </div>
                    <div className="flex justify-center items-center px-[15px]">
                        <a href="https://twitter.com/dreamventuresph" target="_blank" rel="">
                            <img src="./partners/dream.png" width='65px' height='64px' alt="" />
                        </a>
                    </div>
                    <div className="flex justify-center items-center px-[15px]">
                        <a href="https://twitter.com/CapitalDAOio" target="_blank" rel="">
                            <img src="./partners/capitaldao.png" width='97px' height='65px' alt="" />
                        </a>
                    </div>
                    <div className="flex justify-center items-center px-[15px]">
                        <a href="https://aigventure.com/#home" target="_blank" rel="">
                            <img src="./partners/alpha.png" width='95px' height='67px' alt="" />
                        </a>
                    </div>
                    <div className="flex justify-center items-center px-[15px]">
                        <a href="https://croc-capital.com/" target="_blank" rel="">
                            <img src="./partners/croc capital.png" width='119px' height='68px' alt="" />
                        </a>
                    </div>
                    <div className="flex justify-center items-center px-[15px]">
                        <a href="https://dutchcryptoinvestors.com/" target="_blank" rel="">
                            <img src="./partners/dutch.png" width='164px' height='116px' alt="" />
                        </a>
                    </div>
                    <div className="flex justify-center items-center px-[15px]">
                        <a href="https://skyx.fund/" target="_blank" rel="">
                            <img src="./partners/skyx.png" width='217px' height='40px' alt="" />
                        </a>
                    </div>
                    <div className="flex justify-center items-center px-[15px]">
                        <a href="https://planckx.io/" target="_blank" rel="">
                            <img src="./partners/planck.svg" width='77px' height='40px' alt="" />
                        </a>
                    </div>
                    <div className="flex justify-center items-center px-[15px]">
                        <a href="https://entice.dev/" target="_blank" rel="">
                            <img src="./partners/entice.png" width='210px' height='40px' alt="" />
                        </a>
                    </div>
                </div>
                <div className="flex md:hidden  flex-wrap justify-center">
                    <div className="flex justify-center items-center px-[15px]">
                        <a href="https://horizonland.app/" target="_blank" rel="">
                            <img src="./partners/Horizon.png" width='100px' height='10px' alt="" />
                        </a>
                    </div>
                    <div className="flex justify-center items-center px-[15px]">
                        <a href="https://bitcourier.co.uk/news/goldefy-interview" target="_blank" rel="">
                            <img src="./partners/bitcourier.png" width='170px' height='61px' alt="" />
                        </a>
                    </div>
                    <div className="flex justify-center items-center px-[15px]">
                        <a href="https://www.oddiyana.ventures" target="_blank" rel="" >
                            <img src="./partners/oddiyana.png" width='60px' height='32px' alt="" />
                        </a>
                    </div>
                    <div className="flex justify-center items-center px-[15px]">
                        <a href="https://spintop.network/" target="_blank" rel="" >
                            <img src="./partners/spintop.png" width='100px' height='22px' alt="" />
                        </a>
                    </div>
                    <div className="flex justify-center items-center px-[15px]">
                        <a href="https://breach.gg/" target="_blank" rel="">
                            <img src="./partners/breach.png" width='72px' height='27px' alt="" />
                        </a>
                    </div>

                    <div className="flex justify-center items-center px-[15px]">
                        <a href="https://rageeffect.io/" target="_blank" rel="" >
                            <img src="./newIcon/3.png" width='72px' height='27px' alt="" />
                        </a>
                    </div>
                    <div className="flex justify-center items-center px-[15px]">
                        <a href="https://myria.com/" target="_blank" rel="">
                            <img src="./newIcon/myria.png" width='50px' height='27px' alt="" />
                        </a>
                    </div>
                    <div className="flex justify-center items-center px-[15px]">
                        <a href="https://www.petto.io/" target="_blank" rel="">
                            <img src="./newIcon/petto.png" width='72px' height='27px' alt="" />
                        </a>
                    </div>

                    <div className="flex justify-center items-center px-[15px]">
                        <a href="https://coincodex.com/" target="_blank" rel="">
                            <img src="./partners/coindex.jpg" width='93px' height='17px' alt="" />
                        </a>
                    </div>
                    <div className="flex justify-center items-center px-[15px]">
                        <a href="https://bitkeep.com/" target="_blank" rel="">
                            <img src="./partners/bitkeep.png" width='100px' height='32px' alt="" />
                        </a>
                    </div>
                    <div className="flex justify-center items-center px-[15px]">
                        <a href="https://binstarter.io/" target="_blank" rel="">
                            <img src="./partners/bitstarter.png" width='99px' height='30px' alt="" />
                        </a>
                    </div>
                    <div className="flex justify-center items-center px-[15px]">
                        <a href="https://startfi.io/" target="_blank" rel="">
                            <img src="./partners/startfi.png" width='108px' height='51px' alt="" />
                        </a>
                    </div>
                    <div className="flex justify-center items-center px-[15px]">
                        <a href="https://kwikstarter.org/" target="_blank" rel="">
                            <img src="./partners/kwikstarter.png" width='107px' height='18px' alt="" />
                        </a>
                    </div>
                    <div className="flex justify-center items-center px-[15px]">
                        <a href="https://www.seedthriftventures.com/" target="_blank" rel="">
                            <img src="./partners/seedthrift.png" width='113px' height='80px' alt="" />
                        </a>
                    </div>
                    <div className="flex justify-center items-center px-[15px]">
                        <a href="https://www.dapp-playstore.com/" target="_blank" rel="">
                            <img src="./partners/das.png" width='95px' height='65px' alt="" />
                        </a>
                    </div>
                    <div className="flex justify-center items-center px-[15px]">
                        <a href="https://kryptoplayboycapital.com/" target="_blank" rel="">
                            <img src="./partners/kpc.png" width='100px' height='70px' alt="" />
                        </a>
                    </div>
                    <div className="flex justify-center items-center px-[15px]">
                        <a href="https://whitelistventures.com/" target="_blank" rel="">
                            <img src="./partners/whitelist.png" width='115px' height='32px' alt="" />
                        </a>
                    </div>
                    <div className="flex justify-center items-center px-[15px]">
                        <a href="https://www.fairumventures.com/" target="_blank" rel="">
                            <img src="./partners/fairum.png" width='99px' height='17px' alt="" />
                        </a>
                    </div>
                    <div className="flex justify-center items-center px-[15px]">
                        <a href="https://www.solrdao.com/" target="_blank" rel="">
                            <img src="./partners/solrdao.png" width='87px' height='62px' alt="" />
                        </a>
                    </div>
                    <div className="flex justify-center items-center px-[15px]">
                        <a href="https://www.youtube.com/channel/UCX9zgvx6BLkkuprVd5Zy-Wg" target="_blank" rel="">
                            <img src="./partners/incrypto.png" width='82px' height='58px' alt="" />
                        </a>
                    </div>
                    <div className="flex justify-center items-center px-[15px]">
                        <a href="https://alphahunt.club/" target="_blank" rel="">
                            <img src="./partners/alphahunt.png" width='114px' height='52px' alt="" />
                        </a>
                    </div>
                    <div className="flex justify-center items-center px-[15px]">
                        <a href="https://www.bird.money/" target="_blank" rel="">
                            <img src="./partners/bird.png" width='86px' height='35px' alt="" />
                        </a>
                    </div>
                    <div className="flex justify-center items-center px-[15px]">
                        <a href="https://daostarter.pro/#/" target="_blank" rel="">
                            <img src="./partners/daostater.png" width='112px' height='23px' alt="" />
                        </a>
                    </div>
                    <div className="flex justify-center items-center px-[15px]">
                        <a href="https://vulturepeak.io/" target="_blank" rel="">
                            <img src="./partners/vulturepeak.png" width='59px' height='62px' alt="" />
                        </a>
                    </div>
                    <div className="flex justify-center items-center px-[15px]">
                        <a href="https://battlearena.gg/" target="_blank" rel="">
                            <img src="./partners/battlearena.png" width='54px' height='38px' alt="" />
                        </a>
                    </div>
                    <div className="flex justify-center items-center px-[15px]">
                        <a href="https://supraoracles.com/" target="_blank" rel="">
                            <img src="./partners/supraoracles.png" width='29px' height='40px' alt="" />
                        </a>
                    </div>
                    <div className="flex justify-center items-center px-[15px]">
                        <a href="https://safelaunch.io/" target="_blank" rel="">
                            <img src="./partners/safelaunch.png" width='58px' height='49px' alt="" />
                        </a>
                    </div>
                    <div className="flex justify-center items-center px-[15px]">
                        <a href="https://www.goldefy.com/#" target="_blank" rel="">
                            <img src="./partners/budha.png" width='32px' height='52px' alt="" />
                        </a>
                    </div>
                    <div className="flex justify-center items-center px-[15px]">
                        <a href="https://www.vespertine.capital/" target="_blank" rel="">
                            <img src="./partners/vespertine.png" width='54px' height='53px' alt="" />
                        </a>
                    </div>
                    <div className="flex justify-center items-center px-[15px]">
                        <a href="https://twitter.com/dreamventuresph" target="_blank" rel="">
                            <img src="./partners/dream.png" width='46px' height='46px' alt="" />
                        </a>
                    </div>
                    <div className="flex justify-center items-center px-[15px]">
                        <a href="https://twitter.com/CapitalDAOio" target="_blank" rel="">
                            <img src="./partners/capitaldao.png" width='70px' height='47px' alt="" />
                        </a>
                    </div>
                    <div className="flex justify-center items-center px-[15px]">
                        <a href="https://aigventure.com/#home" target="_blank" rel="">
                            <img src="./partners/alpha.png" width='69px' height='49px' alt="" />
                        </a>
                    </div>
                    <div className="flex justify-center items-center px-[15px]">
                        <a href="https://croc-capital.com/" target="_blank" rel="">
                            <img src="./partners/croc capital.png" width='67px' height='38px' alt="" />
                        </a>
                    </div>
                    <div className="flex justify-center items-center px-[15px]">
                        <a href="https://dutchcryptoinvestors.com/" target="_blank" rel="">
                            <img src="./partners/dutch.png" width='92px' height='65px' alt="" />
                        </a>
                    </div>
                    <div className="flex justify-center items-center px-[15px]">
                        <a href="https://skyx.fund/" target="_blank" rel="">
                            <img src="./partners/skyx.png" width='156px' height='29px' alt="" />
                        </a>
                    </div>
                    <div className="flex justify-center items-center px-[15px]">
                        <a href="https://planckx.io/" target="_blank" rel="">
                            <img src="./partners/planck.svg" width='60px' height='29px' alt="" />
                        </a>
                    </div>
                    <div className="flex justify-center items-center px-[15px]">
                        <a href="https://entice.dev/" target="_blank" rel="">
                            <img src="./partners/entice.png" width='180px' height='29px' alt="" />
                        </a>
                    </div>

                    
                </div>
            </div>
            <div className="footer_background">
                
            </div>
        </div>
    )
}

export default Partners ;


const Playcard =({url , title , context})=>{
    return(
        <div className="flex flex-col px-[20px] pb-[50px]">
            <div className="card_background hidden justify-center p-[20px] md:flex">
                <img src={`./${url}`} width="313px"  height='313px' alt="" />
            </div>
            <div className="card_background_mobile flex justify-center p-[15px] md:hidden">
                <img src={`./${url}`} width="215px"  height='215px' alt="" />
            </div>
            <div className="w-[235px] md:w-[312px] flex-col items-center justify-center  ">
                <div className="text-white text-[18px] md:text-[25px] font-bold  flex justify-center pt-[29px] md:pt-[50px] pb-[19px] ">
                    {title}
                </div>
                <div className=" text-[white]  text-[14px] flex justify-center items-center text-center ">
                    {context}
                </div>
            </div>
        </div>
    )
}
export default Playcard ;
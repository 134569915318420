import { useEffect, React ,useState,Fragment } from 'react' ;

import Carousel from "react-multi-carousel" ;
import "react-multi-carousel/lib/styles.css" ;
import NFTcard from "./NFTcard" ;
import ScrollMenu from 'react-horizontal-scrolling-menu';


const NFT =()=>{


    const nfts =[
        {id:'1' , imgurl: 'nfts/1.png'},
        {id:'2' , imgurl: 'nfts/2.png'},
        {id:'3' , imgurl: 'nfts/3.png'},
        {id:'4' , imgurl: 'nfts/4.png'},
        {id:'5' , imgurl: 'nfts/5.png'},
        {id:'6' , imgurl: 'nfts/6.png'},
        {id:'7' , imgurl: 'nfts/7.png'},
        {id:'8' , imgurl: 'nfts/8.png'},
        {id:'9' , imgurl: 'nfts/9.png'},
        {id:'10' , imgurl: 'nfts/10.png'},
        {id:'11' , imgurl: 'nfts/11.png'},
        {id:'12' , imgurl: 'nfts/12.png'},
        {id:'13' , imgurl: 'nfts/13.png'},
        {id:'14' , imgurl: 'nfts/14.png'},
        {id:'15' , imgurl: 'nfts/15.png'},
    ]
    const pets=[
        {id:'1' , imgurl: 'pet1.png'},
        {id:'2' , imgurl: 'pet2.png'},
        {id:'3' , imgurl: 'pet3.png'},
        {id:'4' , imgurl: 'pet4.png'},
        {id:'5' , imgurl: 'pet5.png'},
        
    ]
   
    const [isNfts , setNFTs] = useState(nfts) ;

    
const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }

  };

  const scrollData = (
    isNfts?.map((item , inx)=>(
        <NFTcard url={item.imgurl} inx = {inx} key = {inx} />
    ))
  )

  const petData = (
    pets?.map((item , inx)=>(
        <NFTcard url={item.imgurl} inx = {inx} key = {inx} />
    ))
  )
    return (
        <div className="bg-[#071F4D] pt-[40px] pb-[150px] overflow-hidden " >
            <div className="text-[white] text-[28px] md:text-[60px] flex justify-center font-bold text-center items-center ">
                NFT
            </div>
            <div className=" text-[12px] md:text-[17px] text-[white] flex flex-col items-center pt-[16px] md:pt-[33px]">
                <div className="px-[50px] md:px-[150px] flex justify-center text-center">
                    Earn your NFTs item by playing the game and sell it on the marketplace to make money
                </div>
            </div>
            <div className=" mt-[80px] ">
                <ScrollMenu 
                    wheel={false}
                    translate={1} 
                    selected="4"
                    scrollToSelected ={true}
                    data={scrollData} 
                    
                    hideSingleArrow={true}
                    dragging={true}
                    alignCenter={false}
                    arrowDisabledClass={''}
                    arrowClass='arrow'
                />
            </div>
            <div className="mt-[32px] md:mt-[50px] flex justify-center ">
                <ScrollMenu 
                    wheel={false}
                    translate={1} 
                    
                    data={petData} 
                    
                    hideSingleArrow={true}
                    dragging={true}
                    alignCenter={false}
                    arrowDisabledClass={''}
                    arrowClass='arrow'
                />
            </div>
          

            

            {/*  */}
            
        </div>
    )
}

export default NFT ;
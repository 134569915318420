

const LogoMain = () => {
    return (
        <div className="base1_bg relative -mt-[40px] z-0">
            <div className="pt-[259px] pl-[82px] pb-[1000px] hidden sm:block">
                <div className="hidden sm:block">
                    <img src="./logo_header.png" width='639px' height='267px' alt="" />
                </div>
                <div className="text-[#000000] text-[17px] max-w-[637px] font-[500] pt-[63px] hidden sm:block ">
                    Goldefy is the decentralized blockchain-based mining game platform where you can buy NFT characters and NFT estate ,enjoy PVP battle, mine gold and gems for actual profit. Goldefy is a combination of idle game and mining game, all merged by blockchain and NFT technologies. This will be the new leading trend among NFT game market due to low barrier of entry and easily get used to the system.
                </div>
                <div className="flex flex-wrap  justify-around pt-[23px] max-w-[637px]">
                    {/* <div className="cursor-pointer">
                        <a href="https://play.google.com/store/apps/details?id=miningapp.goldefy.com&hl=en_US&gl=US&utm_medium=social&utm_source=linktree&utm_campaign=free+mining+app+on+google+play" target="_blank" rel="">
                            <img src="./googlkeplay.png" width='268px' height='81px' alt="" />
                        </a>
                    </div>
                    <div className="cursor-pointer">
                        <a href="https://apps.apple.com/app/goldefy/id1609743856" target="_blank" rel="">
                            <img src="./appstore.png" width='268px' height='81px' alt="" />
                        </a>

                    </div> */}

                </div>
            </div>

            <div className="pt-[200px] -translate-x-1/2	ml-[50%] pb-[1000px] flex flex-col sm:hidden">

                <div className="pt-[200px]">
                    {/* <div className="pb-[10px] cursor-pointer">
                        <a href="https://play.google.com/store/apps/details?id=miningapp.goldefy.com&hl=en_US&gl=US&utm_medium=social&utm_source=linktree&utm_campaign=free+mining+app+on+google+play" target="_blank" rel="">
                            <img src="./googlkeplay.png" width='268px' height='81px' alt="" />
                        </a>
                    </div>
                    <div className="cursor-pointer">
                        <a href="https://apps.apple.com/app/goldefy/id1609743856" target="_blank" rel="">
                            <img src="./appstore.png" width='268px' height='81px' alt="" />
                        </a>
                    </div> */}
                </div>
            </div>


        </div>
    )
}

export default LogoMain;   
import { useEffect, useState } from 'react';
import axios from 'axios' ;
import { useLocation } from 'react-router-dom' ;

const ForgotPass =()=>{

    const [temporaryPass , setTemporaryPass] = useState('') ;
    const [newPass , setNewPass] = useState('') ;
    const [confirmPass , setConfirmPass] = useState('') ;
    const [email , setEmail] = useState('') ;
    const location = useLocation() ;

    const confirm =()=>{
        let headers = new Headers();
        // headers.append('Access-Control-Allow-Credentials', 'true');
        headers.append('Access-Control-Allow-Origin','*');
        headers.append('Access-Control-Allow-Methods','GET, POST, PATCH, PUT, DELETE, OPTIONS');
        headers.append('Access-Control-Allow-Headers','Origin, Content-Type, X-Auth-Token');

        axios.post('/user/reset_password', {
            prepass:temporaryPass ,
            password: newPass ,
            confirm: confirmPass,
            email: email 
          })
          .then(function (resp) {
            if(resp.data.status) {
                alert('Password changed.') ;
            } else {
                alert('Can not password change.') ;
            }
          })
          .catch(function (error) {
            alert('Can not password change.') ;
        }) ;
    }

    useEffect(()=>{
        // console.log(location.pathname.split('/')[location.pathname.split('/').length-1]) ;
        setEmail(location.pathname.split('/')[location.pathname.split('/').length-1]) ;
    },[]) ;

    return(
        <div className='bg-[#f5f8fa] h-screen select-none '>
            <div className="flex flex-col justify-center items-center  pt-[70px] sp415min:pt-[100px]">
                <div className='flex items-center justify-center mb-[30px] sp415min:mb-[40px]'>
                    <img src="./../logo.png" width='170px' height='100px' alt="logo img" />
                </div>
                <div className="bg-[#ffffff] w-[600px] mx-[10px] font-[500] text-[#333] max-w-[100%] flex flex-col items-center justify-center ">
                    <div className=' font-[600] text-[24px] text-[black] mb-[20px] mt-[10px] '>
                        Hello Goldians
                    </div>
                    <div className="text-[#777] text-[17px] mb-[20px] ">
                        Reset your password by following.
                    </div>
                    <div className='flex justify-center text-[13px] sp415min:text-[16px]'>
                        <div className="flex flex-col items-end">
                            <div className=" text-[#333] ">Temporary password :	</div>
                            <div className=" text-[#333] ">New password :		</div>
                            <div className=" text-[#333] ">Confirm password :		</div>
                        </div>
                        <div className="flex flex-col items-start pl-[10px]">
                            <div className=" text-[#333] "><input type='password' placeholder='input temporary password' onChange={(e)=>{setTemporaryPass(e.target.value)}} value={temporaryPass} /></div>
                            <div className=" text-[#333] "><input type='password' placeholder='input new password'  onChange={(e)=>{setNewPass(e.target.value)}} value={newPass} /></div>
                            <div className=" text-[#333] "><input type='password' placeholder='confirm new password' onChange={(e)=>{setConfirmPass(e.target.value)}} value={confirmPass} /></div>
                        </div>
                    </div>
                    <div className='cursor-pointer	 bg-[#333] text-[white] w-[100%] p-[5px] text-center text-[16px] mt-[20px] mb-[30px] sp415min:mb-[50px] font-[500]' onClick={confirm} >
                        confirm
                    </div>
                    <div className='h-[0px] w-[100%] border-[#e1e1e1] border-[1px] mb-[20px] sp415min:mb-[60px] '></div>
                    <div className='flex flex-wrap justify-center w-[80%] text-[14px] sp415min:text-[16px]'>
                        <div className="text-[#777] px-[10px] sp415min:px-[15px] pt-[2px] sp415min:pt-[5px] "><a className='decoration-solid' href="https://goldefy.com" target="_blank" >Website</a></div>
                        <div className="text-[#777] px-[10px] sp415min:px-[15px] pt-[2px] sp415min:pt-[5px] "><a className='decoration-solid' href="https://twitter.com/Goldefy_" target="_blank" >Twitter</a></div>
                        <div className="text-[#777] px-[10px] sp415min:px-[15px] pt-[2px] sp415min:pt-[5px] "><a className='decoration-solid' href="https://t.me/+b6kDnoE4t7s2OTJl" target="_blank" >Telegram Group</a></div>
                        <div className="text-[#777] px-[10px] sp415min:px-[15px] pt-[2px] sp415min:pt-[5px] "><a className='decoration-solid' href="https://t.me/+b6kDnoE4t7s2OTJl" target="_blank" >Telegram Announcement</a></div>
                        <div className="text-[#777] px-[10px] sp415min:px-[15px] pt-[2px] sp415min:pt-[5px] "><a className='decoration-solid' href="https://discord.gg/4eVxZP4He4" target="_blank" >Discord</a></div>
                        <div className="text-[#777] px-[10px] sp415min:px-[15px] pt-[2px] sp415min:pt-[5px] "><a className='decoration-solid' href="https://medium.com/@GoldeFy" target="_blank" >Medium</a></div>
                        <div className="text-[#777] px-[10px] sp415min:px-[15px] pt-[2px] sp415min:pt-[5px] "><a className='decoration-solid' href="https://www.reddit.com/r/GoldeFyNFT/" target="_blank" >Reddit</a></div>
                    </div>
                    <div className='text-[#777] text-[14px] sp415min:text-[16px] px-[10px] font-[480] mt-[10px] sp415min:mt-[30px] mb-[10px] sp415min:mb-[20px]'>
                        If you’re having trouble clicking the "Verify Email" button, copy and paste the URL below into your web browser: <a className='text-[#4368CC]' href='https://goldefy.com' target="_blank">https://goldefy.com</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgotPass ;